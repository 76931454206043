import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { isNil } from 'lodash';

import { IAddPaymentMethodRegularData } from 'actions/paymentMethod';
import { routes } from 'utils/routeHelper';
import { formatExpiryDateFromPayix } from 'utils/format';
import {
  US_PAYIX_IFRAME_PATH,
  CANADA_PAYIX_IFRAME_PATH,
  PAYMENT_METHOD_TYPES,
  PAYMENT_METHOD_VENDORS,
} from 'utils/constants';
import { LoadingSpinner } from 'components/widgets';

import './PayixIFrame.scss';

interface IProps {
  borrower: Nullable<IBorrower>;
  loan: ILoan;
  onAddedSuccess: (result: IAddPaymentMethodRegularData) => void;
  isAdding: boolean;
}

export const getPayixIframeSettings = (borrower: IBorrower): { baseSrc: string } => {
  switch (borrower.countryISO.toUpperCase()) {
    case 'USA':
    case 'US':
      return {
        baseSrc: US_PAYIX_IFRAME_PATH,
      };
    case 'CA':
    case 'CAN':
    case 'CANADA':
      return {
        baseSrc: CANADA_PAYIX_IFRAME_PATH,
      };
    default:
      return {
        baseSrc: '',
      };
  }
};

export const getNameAndMethodType = (data: { nameOnCard?: string; nameOnAch?: string; nameOnPad?: string }) => {
  let name;
  let methodType;
  const { nameOnCard, nameOnAch, nameOnPad } = data;

  if (!isNil(nameOnCard)) {
    name = nameOnCard;
    methodType = PAYMENT_METHOD_TYPES.CARD;
  } else if (!isNil(nameOnAch)) {
    name = nameOnAch;
    methodType = PAYMENT_METHOD_TYPES.ACH;
  } else if (!isNil(nameOnPad)) {
    name = nameOnPad;
    methodType = PAYMENT_METHOD_TYPES.PAD;
  }
  return { name, methodType };
};

export const buildPaymentMethodResult = (data: any): IAddPaymentMethodRegularData => {
  const { token, lastFourDigits, cardBrand, expiryDate, billingZip, bankName } = data;
  const { name, methodType } = getNameAndMethodType(data);
  const expiry = expiryDate ? formatExpiryDateFromPayix(expiryDate) : undefined;
  return {
    token,
    paymentMethod: methodType,
    name,
    last4: lastFourDigits,
    expiry,
    type: cardBrand,
    vendor: PAYMENT_METHOD_VENDORS.PAYIX,
    bankName,
    billingZip,
  };
};

const PayixIFrame = (props: IProps) => {
  const [isLoadingIFrame, setIsLoadingIFrame] = useState(true);

  useEffect(() => {
    window.addEventListener('message', onAddedPaymentMethod);

    return () => {
      window.removeEventListener('message', onAddedPaymentMethod);
    };
  }, []);

  const { borrower, loan, onAddedSuccess, isAdding } = props;
  if (!borrower) return <Redirect to={routes.MY_PAYMENT_METHODS} />;

  const iframeSettings = getPayixIframeSettings(borrower);
  const iframeSrc = `${iframeSettings.baseSrc}?account=${loan.id}`;
  const isIframeVisible = !isAdding && !!iframeSrc;
  const isLoaderVisible = isAdding || isLoadingIFrame;

  const onAddedPaymentMethod = (event: { data?: any }) => {
    const { data } = event;
    const isSuccess = data && data.token;
    if (isSuccess) {
      const result = buildPaymentMethodResult(data);
      onAddedSuccess(result);
    }
  };

  const onLoadedIFrame = () => {
    setIsLoadingIFrame(false);
  };

  return (
    <>
      {isLoaderVisible && <LoadingSpinner styleName="custom loading" />}
      {isIframeVisible && <iframe title="Payix Iframe" src={iframeSrc} onLoad={onLoadedIFrame} styleName="iframe" />}
    </>
  );
};

export default PayixIFrame;
