import { combineReducers } from 'redux';

import { actionTypes } from 'actions/user';
import app from './app';
import user from './user';
import loan from './loan';
import paymentMethod from './paymentMethod';
import payment from './payment';
import internalServerErrorDialog from './internalServerErrorDialog';
import convenienceFee from './convenienceFee';
import primaryPaymentMethodInfo from './primaryPaymentMethodInfo';
import documents from './documents';

const appReducer = combineReducers({
  app,
  user,
  loan,
  paymentMethod,
  payment,
  internalServerErrorDialog,
  convenienceFee,
  primaryPaymentMethodInfo,
  documents,
});

const rootReducer = (state: IRootState, action: IAction<any>) => {
  if (action.type === actionTypes.LOG_OUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
