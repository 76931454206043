import { createAction, Action } from 'redux-actions';

export const actionTypes = {
  SET_RETURN_URL: 'SET_RETURN_URL',
} as const;

export const setReturnUrl: (returnUrl: string) => Action<string> = createAction(actionTypes.SET_RETURN_URL);

export type TAppAction = {
  type: typeof actionTypes.SET_RETURN_URL;
  payload: string;
};
