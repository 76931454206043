import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { List, ListItem, Box } from '@material-ui/core';

import * as loanSelectors from 'selectors/loan';
import * as paymentSelectors from 'selectors/payment';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import { selectPayment } from 'actions/payment';
import { routes } from 'utils/routeHelper';
import { showNotification } from 'utils/notification';
import { formatAmount, formatDate, getAmountInDollars } from 'utils/format';
import { checkIsPaidScheduledPayment, checkIsLateScheduledPayment } from 'utils/payment';

import { IconTada } from 'assets/images';
import { Col, ActionButton } from 'components/widgets';

import './LoanOverviewTabScheduled.scss';
import { capitalize } from 'lodash';

interface IProps {
  loanId: string;
}

export const LoanOverviewTabScheduled = (props: IProps) => {
  const { loanId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isPendingLoan,
    isPaidoffLoan,
    scheduledPaymentById,
    nextScheduledPayment,
    isFetchingPaymentMethods,
    paymentMethodCount,
    duePaymentDues,
    paidPaymentDues,
    isAutopayEnabled,
    primaryPaymentMethodLast4,
  } = useSelector((state: IRootState) => {
    return {
      isPendingLoan: loanSelectors.isPendingLoanSelector(loanId)(state),
      isPaidoffLoan: loanSelectors.isPaidoffLoanSelector(loanId)(state),
      scheduledPaymentById: paymentSelectors.scheduledPaymentByIdSelector(state),
      nextScheduledPayment: paymentSelectors.loanNextScheduledPaymentSelector(loanId)(state),
      isFetchingPaymentMethods: paymentMethodSelectors.isFetchingSelector(state),
      paymentMethodCount: paymentMethodSelectors.paymentMethodCountSelector(state),
      duePaymentDues: loanSelectors.paymentDuesByStatusSelector(loanId, 'DUE')(state),
      paidPaymentDues: loanSelectors.paymentDuesByStatusSelector(loanId, 'PAID')(state),
      isAutopayEnabled: loanSelectors.isAutopayEnabledSelector(loanId)(state),
      primaryPaymentMethodLast4: loanSelectors.getPrimaryPaymentMethodLast4Selector(loanId)(state),
    };
  });

  const hasPaymentMethods = paymentMethodCount > 0;

  const handleOnClickPaidScheduledPayment = (selectedScheduledPaymentId: string) => {
    dispatch(selectPayment(selectedScheduledPaymentId));
    history.push(routes.PAYMENT_OVERVIEW);
  };

  const handleOnClickUnpaidScheduledPaymentAfterNextPayment = () => {
    const message = `Please pay for scheduled payment due at ${formatDate(nextScheduledPayment!.dueAt)} of loan ${
      nextScheduledPayment!.loanId
    } first.`;
    showNotification(message, { variant: 'info' });
  };

  const handleOnClickScheduledPaymentItem = (selectedScheduledPaymentId: string) => {
    const selectedScheduledPayment = scheduledPaymentById[selectedScheduledPaymentId];

    if (!selectedScheduledPayment || selectedScheduledPayment.isReadonly) {
      return;
    }

    if (checkIsPaidScheduledPayment(selectedScheduledPayment)) {
      return handleOnClickPaidScheduledPayment(selectedScheduledPaymentId);
    }

    if (!nextScheduledPayment || !hasPaymentMethods) {
      return;
    }

    return handleOnClickUnpaidScheduledPaymentAfterNextPayment();
  };

  const renderCongratulationsMessage = () => (
    <div styleName="wrapper congratulation">
      <img src={IconTada} alt="Icon Tada" styleName="icon-congratulation" />
      <div styleName="heading">Congratulations</div>
      <div>
        <span styleName="message">There are no more scheduled payments for that loan, it is&nbsp;</span>
        <span styleName="message bold-text">paid off.&nbsp;</span>
        <span styleName="message">
          To re-apply, you can tap on “Apply for a new payment plan” at the bottom of the screen.
        </span>
      </div>
    </div>
  );

  const renderPaymentDuesSection = () => {
    if (isPaidoffLoan) {
      return renderCongratulationsMessage();
    }

    let paymentDues: IPaymentDues[] = [];
    if (duePaymentDues && duePaymentDues.length && duePaymentDues.length > 1) {
      // First due payment due is removed here but rendered in another section
      paymentDues = [...duePaymentDues].slice(1);
    }
    if (paidPaymentDues && paidPaymentDues.length && paidPaymentDues.length > 0) {
      paymentDues = [...paymentDues, ...paidPaymentDues];
    }
    const paymentDuesList = paymentDues.map((payment) => {
      const paymentAmount = getAmountInDollars(payment.amount.value, payment.amount.unit);
      const isDue = payment.status === 'DUE';
      const isPaid = payment.status === 'PAID';
      // Payment due scheduled payments exist only on autopay plans
      // Otherwise use payment due itself
      let scheduledPayment;
      let scheduledPaymentId = '';
      if (payment.scheduledPayments) {
        scheduledPayment = payment.scheduledPayments[0];
        scheduledPaymentId = scheduledPayment.scheduledPaymentId;
      } else {
        scheduledPayment = payment;
      }

      let tokenPaymentType = '';
      let paymentTokenLast4 = '';
      if (payment.tokens) {
        tokenPaymentType = payment.tokens[0].type;
        paymentTokenLast4 = payment.tokens[0].last4;
      }

      const paidText =
        tokenPaymentType && paymentTokenLast4
          ? `${capitalize(tokenPaymentType.substring(0, 4))}: *${paymentTokenLast4}`
          : '';
      const dueText = isAutopayEnabled ? `Autopay: *${primaryPaymentMethodLast4}` : '';
      const isLate = scheduledPayment ? checkIsLateScheduledPayment(scheduledPayment) : false;

      let duePaymentBtnStatus: 'info' | 'alert' = 'info';
      let duePaymentBtnText = `Autopay ${isAutopayEnabled ? 'On' : 'Off'}`;
      if (isLate) {
        duePaymentBtnStatus = 'alert';
        duePaymentBtnText = 'Due Now';
      }

      return (
        <ListItem
          styleName="payment-dues-list-item"
          button
          key={payment.paymentDueId}
          onClick={() => handleOnClickScheduledPaymentItem(scheduledPaymentId)}
        >
          <div>
            <div styleName="payment-dues-amount">
              <div>{formatAmount(paymentAmount)}</div>
              <div>{isPaid ? paidText : dueText}</div>
            </div>
            <div styleName="payment-dues-date">
              <div>Due: </div>
              <div>{formatDate(payment.dueAt, { outputFormat: 'MM/DD/YY' })}</div>
            </div>
            {isDue ? (
              <ActionButton
                customVariant={duePaymentBtnStatus}
                size="small"
                onClick={() => undefined}
                disabled={isFetchingPaymentMethods}
              >
                {duePaymentBtnText}
              </ActionButton>
            ) : null}
            {isPaid ? (
              <ActionButton customVariant="disabled" size="small">
                Paid
              </ActionButton>
            ) : null}
          </div>
        </ListItem>
      );
    });
    const shouldShowPaymentDuesList = paymentDuesList.length && !isPendingLoan;

    return (
      <Box styleName="wrapper list-scheduled-payments">
        <Col styleName="payment-dues-wrapper">
          <List styleName="list payment-dues">
            {shouldShowPaymentDuesList ? (
              paymentDuesList
            ) : (
              <span>This is where you will see your scheduled payments</span>
            )}
          </List>
        </Col>
      </Box>
    );
  };

  return (
    <>
      <Col xs={12} sm={6} md={4} styleName="col">
        {renderPaymentDuesSection()}
      </Col>
    </>
  );
};

export default LoanOverviewTabScheduled;
