import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { History } from 'history';
import { chain, Dictionary } from 'lodash';

import { usePaymentMethodFetcher, useSelectedLoan, useClientStorage } from 'hooks';
import { makePayment, IMakePaymentData } from 'actions/payment';
import * as loanSelectors from 'selectors/loan';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import * as paymentSelectors from 'selectors/payment';
import { routes } from 'utils/routeHelper';
import { showNotification } from 'utils/notification';
import { formatTotalAmount, formatAmount } from 'utils/format';
import { getPaymentInputSessionKey } from 'utils/loan';

import { Header, Footer, BodyLayout } from 'components/layouts';
import { PaymentDetailsBody, Col, LoadingSpinner } from 'components/widgets';

import './PaymentDetails.scss';

const TRANSLATING_ERROR_MESSAGE: Record<string, string> = {
  balance_insufficient:
    'Your account balance is insufficient. Top-up your account or try with a different payment method',
};

interface IProps {
  history: History;
}

const FORMAT_AMOUNT_OPTIONS = { currencySymbol: '' };

const buildDefaultPaymentAmountByLoan = (loans: ILoan[], nextScheduledPaymentByLoanId: Dictionary<IScheduledPayment>) =>
  chain(loans)
    .keyBy(({ id }) => id)
    .mapValues((loan, loanId) => {
      const nextScheduledPayment = nextScheduledPaymentByLoanId[loanId];
      let defaultAmount: string = '';
      if (nextScheduledPayment) {
        if (nextScheduledPayment.isReadonly) {
          defaultAmount = formatAmount(nextScheduledPayment.principalAmount, FORMAT_AMOUNT_OPTIONS);
        } else {
          defaultAmount = formatTotalAmount(
            [nextScheduledPayment.principalAmount, loan.currentLateFeesPaymentAmountNeedToPay],
            FORMAT_AMOUNT_OPTIONS
          );
        }
      } else if (loan.regularAmount) {
        defaultAmount = formatAmount(loan.regularAmount, FORMAT_AMOUNT_OPTIONS);
      }
      return defaultAmount;
    })
    .value();

export const initializeState = (state: IRootState) => {
  const payableLoans = loanSelectors.payableLoansSelector(state);
  const payableLoanById = loanSelectors.payableLoanByIdSelector(state);
  const nextScheduledPaymentByLoanId = paymentSelectors.nextScheduledPaymentByLoanIdSelector(state);
  const defaultPaymentAmountByLoan = buildDefaultPaymentAmountByLoan(payableLoans, nextScheduledPaymentByLoanId);

  return {
    isFetchedLoans: loanSelectors.isFetchedSelector(state),
    isFetchingLoans: loanSelectors.isFetchingSelector(state),
    isFetchingPaymentMethods: paymentMethodSelectors.isFetchingSelector(state),
    payableLoans,
    payableLoanById,
    stripePaymentMethods: paymentMethodSelectors.stripePaymentMethodsSelector(state),
    payixPaymentMethods: paymentMethodSelectors.payixPaymentMethodsSelector(state),
    nextScheduledPaymentByLoanId,
    defaultPaymentAmountByLoan,
  };
};

const PaymentDetails = (props: IProps) => {
  const { fetchPaymentMethods } = usePaymentMethodFetcher();
  const dispatch = useDispatch();
  const selectedLoan = useSelectedLoan();
  const {
    isFetchedLoans,
    isFetchingPaymentMethods,
    payableLoanById,
    stripePaymentMethods,
    payixPaymentMethods,
    nextScheduledPaymentByLoanId,
    defaultPaymentAmountByLoan,
  } = useSelector(initializeState);
  const { handleRemoveItem } = useClientStorage('session', getPaymentInputSessionKey(selectedLoan));
  const [isFetchingMakePayment, setIsFetchingMakePayment] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleLoadPaymentMethods = () => fetchPaymentMethods();

  const handleOnConfirmAndPay = (data: IMakePaymentData, options: { isFullPayoffAmount: boolean }) => {
    setIsFetchingMakePayment(true);
    setErrorMessage('');

    dispatch(
      makePayment({
        data,
        onSuccess: () => {
          showNotification('Payment sent. It may take a few minutes to refresh.', { variant: 'success' });
          handleRemoveItem();
          if (options.isFullPayoffAmount) {
            props.history.push(routes.LOAN_PAID_OFF);
          } else {
            props.history.push(routes.HOME);
          }
        },
        onError: (errorMsg: string) => {
          const translatedErrorMessage = TRANSLATING_ERROR_MESSAGE[errorMsg] || errorMsg;
          showNotification('There was an issue with your payment', { variant: 'error' });
          setErrorMessage(translatedErrorMessage);
          setIsFetchingMakePayment(false);
        },
      })
    );
  };

  if (selectedLoan && payableLoanById[selectedLoan.id]) {
    return (
      <div styleName="wrapper">
        <Header styleOptions={{ isSticky: false, isLight: true }} />
        <BodyLayout heading="Make a payment" subHeading={`Loan ID ${selectedLoan.id}`}>
          <PaymentDetailsBody
            isFetchingPaymentMethods={isFetchingPaymentMethods}
            isFetchingMakePayment={isFetchingMakePayment}
            selectedPayableLoan={selectedLoan}
            nextScheduledPaymentByLoanId={nextScheduledPaymentByLoanId}
            defaultPaymentAmountByLoan={defaultPaymentAmountByLoan}
            stripePaymentMethods={stripePaymentMethods}
            payixPaymentMethods={payixPaymentMethods}
            onLoadPaymentMethod={handleLoadPaymentMethods}
            onConfirmAndPay={handleOnConfirmAndPay}
            errorMessage={errorMessage}
          />
        </BodyLayout>
        <Footer />
      </div>
    );
  }

  if (!isFetchedLoans) {
    return (
      <div styleName="wrapper">
        <Header />
        <Col xs={12} sm={6} md={4} styleName="col">
          <LoadingSpinner />
        </Col>
      </div>
    );
  }

  return <Redirect to={routes.HOME} />;
};

export default PaymentDetails;
