import React from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LastLocationProvider } from 'react-router-last-location';
import MomentUtils from '@date-io/moment';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import theme from 'utils/theme';
import { isCordovaApp } from 'utils/constants';
import { ErrorBoundary } from './components/layouts';

import store from './store';
import App from './App';

import './styles/global.scss';

const Root = () => {
  const Router: any = isCordovaApp ? HashRouter : BrowserRouter;

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router>
              <LastLocationProvider>
                <App />
              </LastLocationProvider>
            </Router>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default Root;
