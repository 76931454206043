import React, { ChangeEvent } from 'react';
import { isNil } from 'lodash';
import { TextField, InputAdornment } from '@material-ui/core';

import TextMaskPhoneNumber from './TextMaskPhoneNumber';

import './PhoneNumberInput.scss';

interface IProps {
  value: string;
  areaCode: string;
  label: string;
  errorMessage?: string;
  error?: boolean;
  onChange: (value: string) => void;
}

const PhoneNumberInput = (props: IProps) => {
  const { areaCode, label, value, errorMessage, error } = props;
  const customInputProps = {
    areaCode,
    autoComplete: 'mobile tel-national',
  };

  const onChangePhoneNumber = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (value === '(') {
      props.onChange('');
    } else {
      props.onChange(value);
    }
  };

  return (
    <div styleName="wrapper">
      <TextField
        type="tel"
        name="tel-national"
        styleName="text-field"
        variant="outlined"
        placeholder="(XXX) YYY-ZZZZ"
        label={label}
        onChange={onChangePhoneNumber}
        inputProps={customInputProps}
        InputProps={{
          startAdornment: <InputAdornment position="start">{areaCode}</InputAdornment>,
          inputComponent: TextMaskPhoneNumber,
        }}
        helperText={(error && errorMessage) || ''}
        error={error}
        value={isNil(value) ? '' : `${value}`}
      />
    </div>
  );
};

export default PhoneNumberInput;
