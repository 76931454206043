import { IAddPaymentMethodRegularData, IAddPaymentMethodStripeACHData } from 'actions/paymentMethod';
import { IMakePaymentData, ISendReceiptData } from 'actions/payment';
import { IToggleAutopayData, ISetPrimaryPaymentMethodData } from 'actions/loan';

import axiosClient from './base';

const RENEW_LOANS = '/cache/renew-loans';
const RENEW_BORROWER = '/cache/renew-borrower';
const CREATE_APPLICATION_URL = '/loan-application-urls';
const TOGGLE_AUTOPAY = '/autopay/toggle-autopay';
const SET_PRIMARY_PAYMENT_METHOD = '/autopay/set-primary-token';
const PAYMENT_METHODS = '/payment-methods';
const PAYMENTS_PATH = '/payments';
const SEND_PAYMENT_RECEIPT = '/payments/send-payment-receipt';
const PLAID_LINK_TOKEN = '/plaid-link-token';
const GET_CONVENIENCE_FEE = '/payments/convenience-fee';
const SEND_VOICE_OTP = '/auth/send-voice-otp';
const VERIFY_VOICE_OTP = '/auth/verify-voice-otp';
const DOCUMENTS_PATH = '/documents';
const DOWNLOAD_DOCUMENT = 'documents/download';

export interface IAPIResponse<T = Undefinable<any>> {
  message?: string;
  result?: T;
}

export const renewLoans = () => axiosClient.post(RENEW_LOANS);

export const renewBorrower = () => axiosClient.post(RENEW_BORROWER);

export const createApplicationUrl = (): Promise<IAPIResponse<string>> => {
  return axiosClient.post(CREATE_APPLICATION_URL);
};

export const toggleAutopay = (data: IToggleAutopayData): Promise<IAPIResponse<string>> => {
  return axiosClient.post(TOGGLE_AUTOPAY, data);
};

export const setPrimaryPaymentMethod = (data: ISetPrimaryPaymentMethodData): Promise<IAPIResponse<string>> => {
  return axiosClient.post(SET_PRIMARY_PAYMENT_METHOD, data);
};

export const getPlaidLinkToken = (): Promise<IAPIResponse<string>> => {
  return axiosClient.get(PLAID_LINK_TOKEN);
};

export const getPaymentMethods = (): Promise<IAPIResponse<IPaymentMethod[]>> => {
  return axiosClient.get(PAYMENT_METHODS);
};

export const addPaymentMethod = (
  data: IAddPaymentMethodRegularData | IAddPaymentMethodStripeACHData
): Promise<IAPIResponse> => {
  return axiosClient.post(PAYMENT_METHODS, data);
};

export const deletePaymentMethod = (tokenId: string): Promise<IAPIResponse> => {
  return axiosClient.delete(`${PAYMENT_METHODS}/${tokenId}`);
};

export const makePayment = async (data: IMakePaymentData): Promise<IAPIResponse> => {
  const result: any = await axiosClient.post(PAYMENTS_PATH, data);
  return { message: result.message };
};

export const getPayments = (): Promise<IAPIResponse<IPayment[]>> => {
  return axiosClient.get(PAYMENTS_PATH);
};

export const sendReceipt = (data: ISendReceiptData): Promise<IAPIResponse> => {
  let formData = new FormData();
  formData.append('emails', JSON.stringify(data.emails));
  formData.append('dataUri', data.dataUri);

  return axiosClient.post(SEND_PAYMENT_RECEIPT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getConvenienceFee = (loanInfo: IConvenienceFeeFactors): Promise<IAPIResponse<IConvenienceFee>> => {
  return axiosClient.post(GET_CONVENIENCE_FEE, loanInfo);
};

export const sendVoiceOTP = (phoneNumber: string): Promise<IAPIResponse> => {
  return axiosClient.post(SEND_VOICE_OTP, { phoneNumber });
};

interface IVerifyVoiceOTP {
  message?: string;
  token?: string;
}

export const verifyVoiceOTP = (phoneNumber: string, otp: string): Promise<IVerifyVoiceOTP> => {
  return axiosClient.post(VERIFY_VOICE_OTP, { phoneNumber, otp });
};

export const getDocuments = (): Promise<IAPIResponse<ILoanDocuments[]>> => {
  return axiosClient.get(DOCUMENTS_PATH);
};

export const downloadDocument = (link: string): Promise<File> => {
  return axiosClient.get(`${DOWNLOAD_DOCUMENT}?link=${link}`);
};
