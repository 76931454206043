import React from 'react';
import classNames from 'classnames';
import { AppBar, Box, useScrollTrigger } from '@material-ui/core';

import './ElevationAppbar.scss';

const DEFAULT_ELEVATION = 4;

export interface IStyleOptions {
  isDarkBlue?: boolean;
  isLight?: boolean;
  isSticky?: boolean;
}

export interface IProps {
  children: React.ReactElement | string;
  styleName?: string;
  styleOptions?: IStyleOptions;
}

const DEFAULT_STYLE_OPTIONS = { isDarkBlue: false, isSticky: true };

const ElevationAppbar = (props: IProps) => {
  const isScrolling = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const elevation = isScrolling ? DEFAULT_ELEVATION : 0;
  const { children, styleOptions = {}, ...rest } = props;
  const { isDarkBlue, isLight, isSticky } = { ...DEFAULT_STYLE_OPTIONS, ...styleOptions };

  const appbarStyle = classNames('appbar-wrapper elevation-appbar', {
    'darkblue-appbar': isDarkBlue,
    'light-appbar': isLight,
  });

  const renderContent = () => (typeof children === 'string' ? <span>{children}</span> : children);

  return (
    <div styleName="wrapper">
      {isSticky ? (
        <>
          <AppBar styleName={appbarStyle} elevation={elevation} {...rest}>
            <Box component="nav" styleName="inner-nav inner-nav-sticky">
              {renderContent()}
            </Box>
          </AppBar>
          <div styleName="gap" />
        </>
      ) : (
        <Box component="header" styleName={classNames(appbarStyle, 'unsticky-appbar')}>
          <nav styleName="inner-nav inner-nav-unsticky">{renderContent()}</nav>
        </Box>
      )}
    </div>
  );
};

export default ElevationAppbar;
