import React, { useState } from 'react';
import classNames from 'classnames';
import { MenuItem } from '@material-ui/core';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';

import { LoadingSpinner, SelectField } from 'components/widgets';

import './LoanDropdown.scss';

type Props = Partial<OutlinedTextFieldProps> & {
  loans: ILoan[];
  isFetching?: boolean;
};

const LoanDropdown = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { disabled, loans, isFetching, ...restProps } = props;

  const mergedProps = {
    ...restProps,
    disabled,
    label: 'Loan number',
    options: loans,
    SelectProps: {
      onOpen: () => setIsOpen(true),
      onClose: () => setIsOpen(false),
      renderValue: (selectedValue: any) => {
        const selectedLoan = loans.find((loan) => loan.id === selectedValue);
        return selectedLoan ? (
          <div styleName={classNames('selected-loan', { disabled })}>
            {isFetching && !isOpen && <LoadingSpinner size={14} styleName="spinner" />}
            <div>{selectedLoan.id}</div>
          </div>
        ) : null;
      },
    },
    extraProps: { isOpen },
  };

  return (
    <SelectField {...mergedProps}>
      {loans.map((loan) => {
        const customStyle = classNames('menu-item default-item', {
          selected: loan.id === props.value,
        });
        return (
          <MenuItem key={loan.id} value={loan.id} styleName={customStyle}>
            {loan.id}
          </MenuItem>
        );
      })}
      {isFetching && <LoadingSpinner size={22} styleName="custom loading" />}
    </SelectField>
  );
};

export default LoanDropdown;
