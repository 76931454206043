import React, { useState, useEffect, ChangeEvent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary, isUndefined } from 'lodash';
import numeral from 'numeral';

import { IMakePaymentData } from 'actions/payment';
import { parseToNumber, undoFormatAmount } from 'utils/format';
import {
  PaymentMethodDropdown,
  KeyboardWrapper,
  Col,
  PaymentFields,
  ActionButton,
  GifAnimationWrapper,
  NavigationLink,
} from 'components/widgets';
import { getConvenienceFee } from 'actions/convenienceFee';
import * as convenienceFeeSelectors from 'selectors/convenienceFee';
import { borrowerSelector, getBorrowerAddressStateSelector } from 'selectors/user';
import { isTake5LoanSelector, isAutopayEnabledSelector } from 'selectors/loan';
import { DEVICE_CLASS } from 'utils/constants';
import { getPaymentInputSessionKey, getUseablePaymentMethodsByLoan } from 'utils/loan';
import { useClientStorage } from 'hooks';
import { Info } from '@material-ui/icons';

import './PaymentDetailsBody.scss';
import { routes } from 'utils/routeHelper';
import ConvenienceFeeModal from './ConvenienceFeeModal';
import usePayoffAmount from 'hooks/usePayoffAmount';
import { IField, ISubField } from './PaymentFields';

const getDefaultTokenId = (methods: IPaymentMethod[]): string => {
  if (!methods.length) return '';
  return methods[0].id;
};

interface IProps {
  isFetchingPaymentMethods: boolean;
  isFetchingMakePayment: boolean;
  selectedPayableLoan: ILoan;
  nextScheduledPaymentByLoanId: Dictionary<IScheduledPayment>;
  defaultPaymentAmountByLoan: Record<string, string>;
  stripePaymentMethods: IPaymentMethod[];
  payixPaymentMethods: IPaymentMethod[];
  onLoadPaymentMethod: () => void;
  onConfirmAndPay: (data: IMakePaymentData, options: { isFullPayoffAmount: boolean }) => void;
  errorMessage: Nullable<string>;
}
type TFields = React.ComponentProps<typeof PaymentFields>['fields'];
type TSubFields = TFields[number]['subFields'];

export enum ActiveField {
  PaymentDue = 0,
  CustomAmount = 1,
  FullPaymentAmount = 2,
}

const initializeState = (state: IRootState) => {
  return {
    borrowerState: getBorrowerAddressStateSelector(state),
    isTake5Loan: isTake5LoanSelector(state.loan.selectedLoanId || '')(state),
    isFetchingConvenienceFee: convenienceFeeSelectors.isFetchingConvenienceFeeSelector(state),
    convenienceFee: convenienceFeeSelectors.convenienceFeeSelector(state),
    borrower: borrowerSelector(state),
    selectedPaymentFieldIndex: state.payment.selectedPaymentFieldIndex || ActiveField.PaymentDue,
    isAutopayEnabled: isAutopayEnabledSelector(state.loan.selectedLoanId || '')(state),
  };
};

const PaymentDetailsBody = (props: IProps) => {
  const {
    isFetchingPaymentMethods,
    isFetchingMakePayment,
    selectedPayableLoan,
    nextScheduledPaymentByLoanId,
    defaultPaymentAmountByLoan,
    stripePaymentMethods,
    payixPaymentMethods,
    errorMessage,
  } = props;
  const { item: customAmountStorageItem } = useClientStorage('session', getPaymentInputSessionKey(selectedPayableLoan));
  const { borrower, isFetchingConvenienceFee, convenienceFee, selectedPaymentFieldIndex, isAutopayEnabled } =
    useSelector(initializeState);
  const [errorMsg, setErrorMsg] = useState('');
  const [showConvenienceFeeModal, setShowConvenienceFeeModal] = useState(false);

  const nextScheduledPaymentAmount = defaultPaymentAmountByLoan[selectedPayableLoan.id];
  const initialUseablePaymentMethods = getUseablePaymentMethodsByLoan({
    loan: selectedPayableLoan,
    stripePaymentMethods,
    payixPaymentMethods,
  });
  const convenienceFeeValue = useMemo(() => {
    return convenienceFee.value / convenienceFee.unit || 0;
  }, [convenienceFee.value, convenienceFee.unit]);
  const lateFeeValue = useMemo(() => {
    return selectedPayableLoan.currentLateFeesPaymentAmountNeedToPay || 0;
  }, [selectedPayableLoan.currentLateFeesPaymentAmountNeedToPay]);
  const totalNextInstallmentAsNum = useMemo(() => {
    return lateFeeValue + convenienceFeeValue + parseFloat(nextScheduledPaymentAmount);
  }, [lateFeeValue, convenienceFeeValue, nextScheduledPaymentAmount]);
  const totalNextInstallmentAsStr = String(totalNextInstallmentAsNum);
  const [paymentDetailsData, setPaymentDetailsData] = useState<{
    tokenId: string;
    amount: string;
    loanId: string;
    channel: string;
  }>({
    tokenId: getDefaultTokenId(initialUseablePaymentMethods),
    amount: totalNextInstallmentAsStr,
    loanId: selectedPayableLoan.id,
    channel: DEVICE_CLASS,
  });
  const [activePaymentFieldIndex, setActivePaymentFieldIndex] = useState(selectedPaymentFieldIndex);
  const dispatch = useDispatch();

  const currentUseablePaymentMethods = getUseablePaymentMethodsByLoan({
    loan: selectedPayableLoan,
    stripePaymentMethods,
    payixPaymentMethods,
  });

  const scratchBorrowerId = borrower && borrower.scratchBorrowerId ? borrower.scratchBorrowerId : '';

  const { isPayoffAmount } = usePayoffAmount();

  useEffect(() => {
    if (!paymentDetailsData.tokenId) {
      setPaymentDetailsData({
        ...paymentDetailsData,
        tokenId: getDefaultTokenId(currentUseablePaymentMethods),
      });
    }
  }, [paymentDetailsData.tokenId, currentUseablePaymentMethods]);

  useEffect(() => {
    const payload = {
      loanId: selectedPayableLoan.id,
      scratchBorrowerId: scratchBorrowerId,
      tokenId: paymentDetailsData.tokenId,
      isScraLoan: selectedPayableLoan.isSCRALoan || false,
    };
    if (payload.tokenId !== '' && payload.loanId !== '') {
      dispatch(
        getConvenienceFee({
          data: payload,
        }),
      );
    }
  }, [selectedPayableLoan.id, scratchBorrowerId, paymentDetailsData.tokenId, selectedPayableLoan.isSCRALoan]);

  const additionalFees = convenienceFeeValue + lateFeeValue || 0;
  const payoffBalance = selectedPayableLoan.currentPayoffBalance;
  const selectedPaymentAmountAsStr = paymentDetailsData.amount;
  const selectedPaymentAmountAsNum = parseToNumber(selectedPaymentAmountAsStr);
  const totalCustomAmountPayment = parseToNumber(String(customAmountStorageItem)) || 0;
  const actualCustomAmountPayment = numeral(totalCustomAmountPayment).subtract(additionalFees).value() || 0;
  const currencySymbol = '$';

  useEffect(() => {
    let paymentAmount: string;
    const payoffAmount = (payoffBalance && payoffBalance.toString()) || '';
    switch (activePaymentFieldIndex) {
      case ActiveField.CustomAmount:
        paymentAmount = totalCustomAmountPayment.toString();
        break;
      case ActiveField.FullPaymentAmount:
        paymentAmount = payoffAmount;
        break;
      case ActiveField.PaymentDue:
        if (isPayoffAmount) {
          paymentAmount = payoffAmount;
          break;
        }
        paymentAmount = totalNextInstallmentAsStr;
        break;
      default:
        paymentAmount = totalNextInstallmentAsStr;
    }

    setPaymentDetailsData({ ...paymentDetailsData, amount: paymentAmount });
  }, [totalNextInstallmentAsStr, totalCustomAmountPayment, payoffBalance]);

  useEffect(() => {
    if (additionalFees && selectedPaymentAmountAsNum <= additionalFees) {
      setErrorMsg(`Payment must be greater than ${currencySymbol}${additionalFees.toFixed(2)}`);
    } else {
      setErrorMsg('');
    }
  }, [selectedPaymentAmountAsNum, payoffBalance, additionalFees]);

  if (!payoffBalance) return null;

  const handleOnChangePaymentMethod = (event: ChangeEvent<HTMLInputElement>) => {
    const nextTokenId = event.target.value;
    if (isUndefined(nextTokenId)) return;

    setPaymentDetailsData({
      ...paymentDetailsData,
      tokenId: nextTokenId,
    });
  };

  const handleOnChangePaymentAmount = (amount: string) => {
    setPaymentDetailsData({
      ...paymentDetailsData,
      amount,
    });
  };

  const handleOnConfirmAndPay = () => {
    let paymentAmount;
    switch (activePaymentFieldIndex) {
      case ActiveField.PaymentDue:
        if (isPayoffAmount) {
          paymentAmount = String(payoffBalance);
          break;
        }
        paymentAmount = nextScheduledPaymentAmount;
        break;
      case ActiveField.CustomAmount:
        if (selectedPaymentAmountAsNum === payoffBalance) {
          paymentAmount = String(payoffBalance);
        } else {
          paymentAmount = String(actualCustomAmountPayment);
        }
        break;
      case ActiveField.FullPaymentAmount:
        paymentAmount = String(payoffBalance);
        break;
      default:
        paymentAmount = nextScheduledPaymentAmount;
        break;
    }
    paymentAmount = parseFloat(undoFormatAmount(paymentAmount));
    const isFullPayoffAmount = paymentAmount === payoffBalance;
    if (paymentAmount <= 0) return;

    return props.onConfirmAndPay(
      {
        ...paymentDetailsData,
        amount: paymentAmount,
        waiveConvenienceFee: isFullPayoffAmount,
        isSACPayoffPayment: isFullPayoffAmount && selectedPayableLoan.isStillInPromotionalPeriod,
      },
      { isFullPayoffAmount },
    );
  };

  const hasNextScheduledPayment = () => {
    const nextScheduledPayment = nextScheduledPaymentByLoanId[paymentDetailsData.loanId];
    return !!nextScheduledPayment && !nextScheduledPayment.isReadonly;
  };

  const isCustomFieldSelected = activePaymentFieldIndex === ActiveField.CustomAmount;
  const hasPaymentError = () => {
    if (!paymentDetailsData.loanId || !selectedPaymentAmountAsStr) return false;
    if (!hasNextScheduledPayment()) return false;
    if (isCustomFieldSelected) {
      if (!!errorMsg) return true;
      return false;
    }
  };
  const hasInvalidPaymentValue =
    !selectedPaymentAmountAsNum || (isCustomFieldSelected && actualCustomAmountPayment <= 0);
  const isConfirmAndPayButtonDisabled =
    isFetchingMakePayment || !paymentDetailsData.tokenId || hasInvalidPaymentValue || hasPaymentError();

  const subFields: TSubFields = !!additionalFees
    ? [
        {
          inputTitle: 'Late Fee',
          amount: lateFeeValue,
        },
        {
          inputTitle: 'Convenience Fee',
          amount: convenienceFeeValue,
          icon: <Info onClick={() => setShowConvenienceFeeModal(true)} fontSize="inherit" />,
        },
      ]
    : [];
  const inputTitlePlanPayment = 'Scratch Plan Payment';

  const paymentDueField: IField & { subFields?: ISubField[] } = isPayoffAmount
    ? {
        inputTitle: 'Payment due',
        amount: payoffBalance,
      }
    : {
        inputTitle: 'Payment due',
        amount: totalNextInstallmentAsNum,
        subFields: [
          {
            inputTitle: inputTitlePlanPayment,
            amount: parseToNumber(nextScheduledPaymentAmount),
          },
          ...subFields,
        ],
      };
  const paymentFields: TFields = [
    paymentDueField,
    {
      inputTitle: 'Other amount',
      amount: totalCustomAmountPayment,
      subFields: [
        {
          inputTitle: inputTitlePlanPayment,
          amount: actualCustomAmountPayment,
        },
        ...subFields,
      ],
    },
    {
      inputTitle: 'Pay off plan',
      amount: payoffBalance,
    },
  ];
  const shouldDisablePaymentBtn = isConfirmAndPayButtonDisabled || isFetchingConvenienceFee;
  const isFetching = isFetchingConvenienceFee || isFetchingMakePayment;
  const selectedPaymentMethod = currentUseablePaymentMethods.find((method) => method.id === paymentDetailsData.tokenId);
  const selectedPaymentMethodType = (selectedPaymentMethod && selectedPaymentMethod.paymentType) || '';

  return (
    <KeyboardWrapper>
      <div styleName="wrapper">
        <p styleName="card-title">Payment Type:</p>
        <Col styleName="make-payment">
          <PaymentFields
            fields={paymentFields}
            activeFieldIndex={activePaymentFieldIndex}
            setActiveFieldIndex={setActivePaymentFieldIndex}
            value={selectedPaymentAmountAsStr}
            currencySymbol={currencySymbol}
            onChange={handleOnChangePaymentAmount}
            error={hasPaymentError()}
            errorMessage={errorMsg}
            payoffBalance={payoffBalance}
          />
          <div className="fixed-bottom" styleName="error-message">
            {errorMessage}
          </div>
          <ConvenienceFeeModal
            convenienceFeeAmount={convenienceFeeValue}
            show={showConvenienceFeeModal}
            setShow={setShowConvenienceFeeModal}
            isAutopayEnabled={isAutopayEnabled}
          />
        </Col>
        <p styleName="card-title">Payment Details:</p>
        <Col styleName="payment-method">
          <PaymentMethodDropdown
            label={null}
            disabled={isFetchingMakePayment}
            isFetching={isFetchingPaymentMethods}
            paymentMethods={currentUseablePaymentMethods}
            value={paymentDetailsData.tokenId}
            onBeforeOpen={props.onLoadPaymentMethod}
            onChange={handleOnChangePaymentMethod}
          />
        </Col>
        <div styleName="agreement-link">
          <NavigationLink
            text="Electronic Funds Transfer Agreement"
            to={{
              pathname: routes.EFT_AGREEMENT,
              state: {
                borrower,
                paymentDetails: {
                  amount: selectedPaymentAmountAsNum,
                  paymentMethodType: selectedPaymentMethodType.toLowerCase(),
                  paymentMethodLast4: (selectedPaymentMethod && selectedPaymentMethod.last4) || '',
                },
              },
            }}
          />
        </div>
        <div styleName="payment-btn">
          <ActionButton
            id="payment-btn"
            customVariant="standard"
            disabled={shouldDisablePaymentBtn}
            onClick={handleOnConfirmAndPay}
            type="submit"
          >
            {isFetchingMakePayment ? 'Processing...' : 'Make Payment'}
          </ActionButton>
        </div>
      </div>
      {isFetching && <GifAnimationWrapper />}
    </KeyboardWrapper>
  );
};

export default PaymentDetailsBody;
