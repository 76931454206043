import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { routes } from 'utils/routeHelper';

import {
  Contact,
  Login,
  OTPConfirmation,
  Home,
  PaymentDetails,
  PaymentMethodDetails,
  LoanOverview,
  PaymentMethodOverview,
  PaymentOverview,
  UserProfile,
  MyLoans,
  MyPaymentMethods,
  MyPayments,
  MyScheduledPayments,
  MyDocuments,
  EFTAgreement,
  MyLoanPaidOff,
  AutopayAuthTerms,
  AutopayActivation,
  SelectPaymentMethod,
  NoMatch404,
} from 'components/pages';
import AuthRoute from './AuthRoute';
import PrivateRoute from './PrivateRoute';

const RouterContainer: React.FC = () => (
  <Switch>
    <Route path={routes.CONTACT} component={Contact} />
    <AuthRoute path={routes.LOGIN} component={Login} />
    <AuthRoute path={routes.OTP_CONFIRMATION} component={OTPConfirmation} />

    <Redirect exact from={routes.ROOT} to={routes.HOME} />
    <PrivateRoute exact path={routes.HOME} component={Home} />
    <PrivateRoute path={routes.PAYMENT_DETAILS} component={PaymentDetails} />
    <PrivateRoute path={routes.PAYMENT_METHOD_DETAILS} component={PaymentMethodDetails} />
    <PrivateRoute path={routes.LOAN_OVERVIEW} component={LoanOverview} />
    <PrivateRoute path={routes.LOAN_OVERVIEW_BY_UNIQUE_ID} component={LoanOverview} />
    <PrivateRoute path={routes.LOAN_OVERVIEW_BY_UNIQUE_ID_SCHEDULED} component={LoanOverview} />
    <PrivateRoute path={routes.PAYMENT_METHOD_OVERVIEW} component={PaymentMethodOverview} />
    <PrivateRoute path={routes.PAYMENT_OVERVIEW} component={PaymentOverview} />
    <PrivateRoute path={routes.USER_PROFILE} component={UserProfile} />
    <PrivateRoute path={routes.MY_LOANS} component={MyLoans} />
    <PrivateRoute path={routes.LOAN_PAID_OFF} component={MyLoanPaidOff} />
    <PrivateRoute path={routes.MY_PAYMENT_METHODS} component={MyPaymentMethods} />
    <PrivateRoute path={routes.MY_PAYMENTS} component={MyPayments} />
    <PrivateRoute path={routes.MY_SCHEDULED_PAYMENTS} component={MyScheduledPayments} />
    <PrivateRoute path={routes.MY_DOCUMENTS} component={MyDocuments} />
    <PrivateRoute path={routes.EFT_AGREEMENT} component={EFTAgreement} />
    <PrivateRoute path={routes.AUTOPAY_AUTH_TERMS} component={AutopayAuthTerms} />
    <PrivateRoute path={routes.AUTOPAY_ACTIVATION} component={AutopayActivation} />
    <PrivateRoute path={routes.SELECT_PAYMENT_METHOD} component={SelectPaymentMethod} />
    <Route component={NoMatch404} />
  </Switch>
);

export default RouterContainer;
