import React from 'react';
import { pick } from 'lodash';
import { Grid } from '@material-ui/core';
import { GridSize } from '@material-ui/core/Grid';

import './Col.scss';

interface IContainerProps {
  styleName?: string;
  className?: string;
}

interface IItemProps {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
}

type Props = { children: React.ReactNode } & IContainerProps & IItemProps;

const Col = (props: Props) => {
  const containerProps: IContainerProps = pick(props, ['className', 'styleName']);
  const itemProps: IItemProps = pick(props, ['xs', 'sm', 'md']);

  return (
    <div styleName="wrapper">
      <Grid container direction="column" justifyContent="flex-start" alignItems="center" {...containerProps}>
        <Grid item styleName="wrapper-item col" {...itemProps}>
          {props.children}
        </Grid>
      </Grid>
    </div>
  );
};
export default Col;
