import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { Element } from 'react-scroll';

import PaymentPlanListItem from './PaymentPlanListItem';

import './PaymentPlanList.scss';

interface IProps {
  title?: string;
  loans: ILoan[];
  onClickPaymentPlanItem: (loanId: string) => void;
}

const PaymentPlanList = (props: IProps) => {
  const { title, loans, onClickPaymentPlanItem } = props;

  if (!loans.length) return null;

  return (
    <div styleName="wrapper">
      {!!title && <div styleName="title">{title}</div>}
      <List styleName="list payment-plans">
        {loans.map((loan) => (
          <ListItem key={loan.id} styleName="list-item payment-plan">
            <Element name={loan.id}>
              <PaymentPlanListItem loan={loan} onClick={() => onClickPaymentPlanItem(loan.id)} />
            </Element>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default PaymentPlanList;
