import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme();
const { breakpoints } = defaultTheme;

const GRID_4_UP = breakpoints.up('xs');
const GRID_8_UP = breakpoints.up('sm');
const GRID_12_UP = breakpoints.up('md');

export const responsiveMuiFab = {
  [GRID_4_UP]: {
    fontSize: 14,
    height: 48,
  },
  [GRID_8_UP]: {
    fontSize: 18,
    height: 48,
  },
  [GRID_12_UP]: {
    borderRadius: 28,
    fontSize: 18,
    height: 56,
  },
};

export const responsiveMuiButton = {
  [GRID_4_UP]: {
    fontSize: 14,
  },
  [GRID_8_UP]: {
    fontSize: 18,
    width: 'auto',
    paddingLeft: 16,
    paddingRight: 16,
  },
  [GRID_12_UP]: {
    fontSize: 18,
    paddingLeft: 24,
    paddingRight: 24,
  },
};

export const responsiveMuiAppBar = {
  [GRID_4_UP]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [GRID_8_UP]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [GRID_12_UP]: {
    paddingLeft: 24,
    paddingRight: 24,
  },
};

export const responsiveMuiDialogFullScreen = {
  [GRID_4_UP]: {
    marginLeft: 16,
    marginRight: 16,
  },
  [GRID_8_UP]: {
    marginLeft: 0,
    marginRight: 0,
    maxWidth: '50%',
  },
};

export const responsiveMuiSnackbarContentRoot = {
  width: 288,
  [GRID_8_UP]: {
    width: 452,
  },
};

export const responsiveMuiSnackbarContentMessage = {
  fontSize: 14,
  [GRID_8_UP]: {
    fontSize: 16,
  },
};
