// @ts-ignore
import html2pdf from 'html2pdf.js';
import { isNil } from 'lodash';
import moment from 'moment';

import { REGEXES } from 'utils/validate';

type TScheduledPayment = IScheduledPayment | IPaymentDues | IPaymentDuesScheduledPayment;

export const NODE_ID_RECEIPT_TEMPLATE = 'receipt-template';

export const SCHEDULED_PAYMENT_STATUS_TYPE = {
  PAID: 'PAID',
  NEW: 'NEW',
};

export const getReceiptTemplatePDFUri = async (
  filenameSuffix: string
): Promise<{ dataUri: string; filename: string }> => {
  const filename = `receipt_${filenameSuffix}.pdf`;
  const opt = {
    filename,
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 2, logging: true },
    jsPDF: {
      format: 'a4',
      orientation: 'portrait',
    },
  };
  const templateElement = (document as any).getElementById(NODE_ID_RECEIPT_TEMPLATE).cloneNode(true);
  templateElement.style = '';
  const dataUri = await html2pdf().set(opt).from(templateElement).outputPdf('datauristring', { filename });
  return {
    dataUri,
    filename,
  };
};

export const convertListOfSharedEmails = (sharedEmails: string): Nullable<string[]> => {
  const trimmedEmails = sharedEmails.replace(/,+/g, ',').replace(/\s+/g, '').replace(/,$/, '');
  const emails = trimmedEmails.split(',');

  const invalidEmail = emails.find((email) => !REGEXES.EMAIL.test(email));
  if (isNil(invalidEmail)) return emails;
  return null;
};

export const checkIsPaidScheduledPayment = ({ status }: TScheduledPayment) =>
  status === SCHEDULED_PAYMENT_STATUS_TYPE.PAID;

export const checkIsDueScheduledPayment = (scheduledPayment: TScheduledPayment) =>
  !checkIsPaidScheduledPayment(scheduledPayment) && moment(scheduledPayment.dueAt).isSameOrAfter(moment(), 'day');

export const checkIsLateScheduledPayment = (scheduledPayment: TScheduledPayment) =>
  !checkIsPaidScheduledPayment(scheduledPayment) && moment(scheduledPayment.dueAt).isBefore(moment(), 'day');
