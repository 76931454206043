import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';

import {
  useAuthenticationSubscriber,
  useBorrowerSubscriber,
  useLoanAndScheduledPaymentSubscriber,
  usePaymentFetcher,
  usePaymentMethodFetcher,
} from 'hooks';
import RouterContainer from 'routers/RouterContainer';
import { isCordovaApp } from 'utils/constants';

import ScrollToTop from 'components/widgets/ScrollToTop';
import { InternalServerErrorDialog, BetaModeTurnDownDialog } from 'components/widgets';

import 'react-toastify/dist/ReactToastify.css';
import styles from './App.scss';
import useDocumentsFetcher from 'hooks/useDocumentsFetcher';

export const App = () => {
  useBorrowerSubscriber();
  useLoanAndScheduledPaymentSubscriber();
  usePaymentFetcher();
  usePaymentMethodFetcher();
  useDocumentsFetcher();
  const { isChecked } = useAuthenticationSubscriber();
  if (!isChecked) {
    return null;
  }

  return (
    <>
      <ScrollToTop />
      <RouterContainer />
      <ToastContainer
        position="top-center"
        closeButton={false}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        autoClose={3000}
        hideProgressBar
        draggablePercent={40}
        limit={2}
        transition={Slide}
        toastClassName={styles.toast}
      />
      <InternalServerErrorDialog />
      {!isCordovaApp && <BetaModeTurnDownDialog />}
    </>
  );
};

export default App;
