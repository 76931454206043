import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { List, ListItem } from '@material-ui/core';

import { usePaymentMethodFetcher, usePaymentFetcher } from 'hooks';
import { selectPayment } from 'actions/payment';
import { routes } from 'utils/routeHelper';
import { formatAmount, formatDate } from 'utils/format';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import * as paymentSelectors from 'selectors/payment';

import { Header } from 'components/layouts';
import { Col, LoadingSpinner, Fab, IconPaymentMethod } from 'components/widgets';

import './MyPayments.scss';

interface IProps {
  history: History;
}

export const MyPayments = (props: IProps) => {
  usePaymentFetcher();
  usePaymentMethodFetcher();
  const dispatch = useDispatch();
  const {
    isFetchedPaymentdMethods,
    isFetchedPayments,
    isFetchingPaymentMethods,
    isFetchingPayments,
    paymentMethods,
    payments,
  } = useSelector((state: IRootState) => {
    return {
      isFetchedPaymentdMethods: paymentMethodSelectors.isFetchedSelector(state),
      isFetchedPayments: paymentSelectors.isFetchedPaymentsSelector(state),
      isFetchingPaymentMethods: paymentMethodSelectors.isFetchingSelector(state),
      isFetchingPayments: paymentSelectors.isFetchingPaymentsSelector(state),
      paymentMethods: paymentMethodSelectors.paymentMethodsSelector(state),
      payments: paymentSelectors.paymentsSelector(state),
    };
  });

  const isFetched = isFetchedPaymentdMethods && isFetchedPayments;
  const isFetching = isFetchingPaymentMethods || isFetchingPayments;
  const hasPaymentMethods = !!paymentMethods.length;
  const hasPayments = !!payments.length;

  const handleOnClickPaymentItem = (paymentId: number | string) => {
    dispatch(selectPayment(paymentId));
    props.history.push(routes.PAYMENT_OVERVIEW);
  };

  const handleMakePayment = () => props.history.push(routes.PAYMENT_DETAILS);

  const renderPaymentList = () => {
    if (!hasPayments) {
      return hasPaymentMethods ? (
        <div styleName="no-data-wrapper">
          You don’t have any Payment yet. This is where you will see the list of all your payments.
        </div>
      ) : (
        <div styleName="no-data-wrapper">
          You don’t have any Payment yet. To make and view payments here, first you must&nbsp;
          <Link to={routes.PAYMENT_METHOD_DETAILS}>add a Payment method</Link>.
        </div>
      );
    }

    return (
      <List styleName="list payments">
        {payments.map((payment: IPayment) => (
          <ListItem
            button
            key={payment.id}
            styleName={classNames('list-item payment', { 'has-refund': payment.isRefund })}
            onClick={() => handleOnClickPaymentItem(payment.id)}
          >
            <div styleName="date">{formatDate(payment.transactionDate)}</div>
            <div styleName="amount-wrapper">
              {payment.isRefund && <div styleName="tag-refund">Refund</div>}
              <IconPaymentMethod paymentType={payment.paymentMethod.type} />
              <div styleName="amount">{formatAmount(payment.totalAmount)}</div>
            </div>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Col xs={12} sm={6} md={6} styleName="wrapper">
      <Header />
      {isFetching && <LoadingSpinner />}
      {isFetched && renderPaymentList()}
      <Fab onClick={handleMakePayment} disabled={isFetching}>Make a payment</Fab>
    </Col>
  );
};

export default MyPayments;
