// This file is tricks for submit type of button when keyboard is slided up

import { get } from 'lodash';
import { isCordovaApp } from 'utils/constants';

import variables from '../styles/variables.scss';

const BOTTOM_WEB = {
  WITHOUT_KEYBOARD: 20,
  WITH_KEYBOARD: 16,
};

const BOTTOM_APP = {
  WITHOUT_KEYBOARD: 72,
  WITH_KEYBOARD: 10,
};

const MUI_RESPONSIVE_CLASSES = ['MuiGrid-grid-xs-12', 'MuiGrid-grid-sm-12', 'MuiGrid-grid-md-12'];

const setStyle = (element: Element | HTMLElement | null, styleProp: string, styleValue: string) => {
  if (element instanceof HTMLElement) {
    element.style.setProperty(styleProp, styleValue);
  }
};

const getHeightByDevice = () => {
  // https://www.theiphonewiki.com/wiki/Models
  if (isCordovaApp) {
    return {
      keyboardHeightForIPhoneX: 258,
      keyboardHeightForOtherIPhone: 220,
    };
  }

  return {
    keyboardHeightForIPhoneX: 204,
    keyboardHeightForOtherIPhone: 178,
  };
};

const getDevice = (deviceHeight?: number) => {
  let keyboardHeight;

  if (deviceHeight) {
    const isIPhoneX = deviceHeight >= 812;
    const { keyboardHeightForIPhoneX, keyboardHeightForOtherIPhone } = getHeightByDevice();
    keyboardHeight = isIPhoneX ? keyboardHeightForIPhoneX : keyboardHeightForOtherIPhone;
  }

  return {
    keyboardHeight,
    Desktop: !window.navigator.userAgent.match(/iPhone|iPad|iPod|Android/i),
    Android: window.navigator.userAgent.match(/Android/i),
    iOS: window.navigator.userAgent.match(/iPhone|iPad|iPod/i),
  };
};

const updateFixedBottomElement = (bottomAppValue: number) => {
  const fixedBottomElement: Element | null = document.getElementsByClassName('fixed-bottom')[0];
  if (fixedBottomElement) {
    const bottomValue = `calc(${bottomAppValue}px + ${variables.fabHeightLarge} + 8px)`;
    setStyle(fixedBottomElement, 'bottom', bottomValue);
  }
};

export const getExtraHeightByKeyboard = (inputType: Nullable<string>, aboveFabElementHeight?: number) => {
  const extraHeightOfTextKeyboard = 49 - (aboveFabElementHeight || 0);
  return inputType === 'text' || inputType === '' || inputType === null ? extraHeightOfTextKeyboard : 0;
};

const hideElementAboveFab = () => {
  const aboveFabElement: Element | null = document.getElementsByClassName('above-fab')[0];
  if (aboveFabElement) {
    setStyle(aboveFabElement, 'display', 'none');
  }
};

const showElementAboveFab = () => {
  const aboveFabElement: Element | null = document.getElementsByClassName('above-fab')[0];
  if (aboveFabElement) {
    setStyle(aboveFabElement, 'display', 'block');
  }
};

export const onLoad = () => {
  let isScrolling: NodeJS.Timeout | null = null;
  let isFocusin = false;
  const screen = window.screen.height;

  const aboveFabElement: Element | null = document.getElementsByClassName('above-fab')[0];
  const aboveFabElementHeight = aboveFabElement ? (aboveFabElement as HTMLElement).offsetHeight : 0;

  if (isCordovaApp) {
    const keyboardWrapper: Element | null = document.getElementsByClassName('keyboard-wrapper')[0];
    const responsiveGridWrapper: HTMLElement | null =
      keyboardWrapper && keyboardWrapper.querySelector('.responsive-grid-wrapper');
    setStyle(responsiveGridWrapper, 'bottom', `${BOTTOM_APP.WITHOUT_KEYBOARD}px`);
    updateFixedBottomElement(BOTTOM_APP.WITHOUT_KEYBOARD);

    window.addEventListener('keyboardWillShow', () => {
      const keyboardWrapper: Element | null = document.getElementsByClassName('keyboard-wrapper')[0];
      const responsiveGridItemWrapper: HTMLElement | null =
        keyboardWrapper && keyboardWrapper.querySelector('.responsive-grid-item-wrapper');
      if (responsiveGridItemWrapper) {
        responsiveGridItemWrapper.classList.add(...MUI_RESPONSIVE_CLASSES);
        setStyle(responsiveGridItemWrapper, 'padding', '0 10px');
        setStyle(responsiveGridWrapper, 'bottom', `${BOTTOM_APP.WITH_KEYBOARD}px`);
      }
      updateFixedBottomElement(BOTTOM_APP.WITH_KEYBOARD);
    });

    window.addEventListener('keyboardWillHide', () => {
      const keyboardWrapper: Element | null = document.getElementsByClassName('keyboard-wrapper')[0];
      const responsiveGridItemWrapper: HTMLElement | null =
        keyboardWrapper && keyboardWrapper.querySelector('.responsive-grid-item-wrapper');
      if (responsiveGridItemWrapper) {
        responsiveGridItemWrapper.classList.remove(...MUI_RESPONSIVE_CLASSES);
        setStyle(responsiveGridItemWrapper, 'padding', '0');
        setStyle(responsiveGridWrapper, 'bottom', `${BOTTOM_APP.WITHOUT_KEYBOARD}px`);
      }
      updateFixedBottomElement(BOTTOM_APP.WITHOUT_KEYBOARD);
    });
  } else {
    if (getDevice(screen).iOS || getDevice(screen).Android) {
      const originalHeight = window.innerHeight;
      const keyboardWrapper: Element = document.getElementsByClassName('keyboard-wrapper')[0];
      if (keyboardWrapper) {
        const responsiveGridWrapper: HTMLElement | null = keyboardWrapper.querySelector('.responsive-grid-wrapper');
        const responsiveGridItemWrapper: HTMLElement | null = keyboardWrapper.querySelector(
          '.responsive-grid-item-wrapper'
        );

        if (responsiveGridWrapper && responsiveGridItemWrapper) {
          // the total height of keyboard and submit button
          window.addEventListener('scroll', (event) => {
            const element = document.querySelector('input:focus');

            if (getDevice(screen).iOS) {
              const totalHeight =
                (getDevice(screen).keyboardHeight || 0) + responsiveGridWrapper.offsetHeight + BOTTOM_WEB.WITH_KEYBOARD;
              const rootElement = document.getElementById('root');
              const rootOffsetHeight = (rootElement && rootElement.offsetHeight) || 0;
              const stopCondition = originalHeight - window.innerHeight >= rootOffsetHeight;
              if (window.innerHeight <= originalHeight && isFocusin && !stopCondition) {
                setStyle(responsiveGridWrapper, 'position', 'absolute');
                if (isScrolling) {
                  window.clearTimeout(isScrolling);
                }
                isScrolling = setTimeout(() => {
                  const keyboardType = element ? element.getAttribute('inputMode') : '';
                  const bottom = totalHeight + getExtraHeightByKeyboard(keyboardType) - window.scrollY;
                  setStyle(responsiveGridWrapper, 'bottom', `${bottom}px`);
                }, 100);
              }
            }
          });

          // Detect when input element is focused in inside form tag
          keyboardWrapper.addEventListener('focusin', (event) => {
            const tagName: string = get(event.target, 'tagName', '');
            const isInput = tagName === 'INPUT';
            if (!isInput) return;

            let scrollingTimeout: NodeJS.Timeout | null = null;
            isFocusin = true;
            const totalHeight =
              (getDevice(screen).keyboardHeight || 0) + responsiveGridWrapper.offsetHeight + BOTTOM_WEB.WITH_KEYBOARD;

            if (getDevice(screen).iOS) {
              if (window.innerHeight <= originalHeight) {
                if (scrollingTimeout) {
                  window.clearTimeout(scrollingTimeout);
                }
                scrollingTimeout = setTimeout(() => {
                  const keyboardType = get(event.target, 'inputMode', 'text');
                  const bottom =
                    totalHeight + getExtraHeightByKeyboard(keyboardType, aboveFabElementHeight) - window.scrollY;
                  setStyle(responsiveGridWrapper, 'bottom', `${bottom}px`);
                }, 100);
              } else {
                const keyboardType = get(event.target, 'inputMode', 'text');
                const bottom = totalHeight + getExtraHeightByKeyboard(keyboardType, aboveFabElementHeight);
                setStyle(responsiveGridWrapper, 'bottom', `${bottom}px`);
              }
            }

            responsiveGridItemWrapper.classList.add(...MUI_RESPONSIVE_CLASSES);
            setStyle(responsiveGridItemWrapper, 'padding', '0 10px');

            hideElementAboveFab();
          });

          // Detect when input element is focused out inside form tag
          keyboardWrapper.addEventListener('focusout', (event) => {
            isFocusin = false;
            if (getDevice(screen).iOS) {
              setTimeout(() => {
                setStyle(responsiveGridWrapper, 'bottom', `${BOTTOM_WEB.WITHOUT_KEYBOARD}px`);
              }, 0);
            }

            if (getDevice(screen).Android) {
              // @ts-ignore
              setStyle(keyboardWrapper, 'height', '100%');
              setStyle(responsiveGridWrapper, 'bottom', `${BOTTOM_WEB.WITHOUT_KEYBOARD}px`);
            }

            responsiveGridItemWrapper.classList.remove(...MUI_RESPONSIVE_CLASSES);
            setStyle(responsiveGridItemWrapper, 'padding', '0');

            showElementAboveFab();
          });
        }
      }
    }
  }
};
