import React from 'react';
import './NoMatch404.scss';
import { Col } from 'components/widgets';
import { Dog } from 'assets/images';

const NoMatch404 = () => {
  return (
    <Col xs={12} sm={6} md={4} styleName="wrapper">
      <div styleName="content-container">
        <div styleName="heading">
          <img src={Dog} alt="" />
          <div styleName="heading-404">
            <h1>404</h1>
          </div>
          <h1>Page not found</h1>
        </div>

        <div styleName="content">
          <div>The page you are looking for may have been moved, removed or does not exist</div>

          <div styleName="cta-container">
            <a href="/" styleName="cta small">
              {' '}
              GO TO HOMEPAGE{' '}
            </a>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default NoMatch404;
