import { createAction, Action, BaseAction } from 'redux-actions';

export const actionTypes = {
  SET_IS_ADD_PRIMARY_PAYMENT_METHOD: 'SET_IS_ADD_PRIMARY_PAYMENT_METHOD',
  SET_PRIMARY_PAYMENT_METHOD_INFO: 'SET_PRIMARY_PAYMENT_METHOD_INFO',
  RESET_PRIMARY_PAYMENT_METHOD_INFO: 'RESET_PRIMARY_PAYMENT_METHOD_INFO',
};

type SetPrimaryPaymentMethodPayload = Omit<IPrimaryPaymentMethodInfoState, 'isAddPrimaryPaymentMethod'>;

export const setIsAddPrimaryPaymentMethod: (isAddPrimaryPaymentMethod: boolean) => Action<boolean> = createAction(
  actionTypes.SET_IS_ADD_PRIMARY_PAYMENT_METHOD
);

export const setPrimaryPaymentMethodInfo: (
  payload: SetPrimaryPaymentMethodPayload
) => Action<SetPrimaryPaymentMethodPayload> = createAction(actionTypes.SET_PRIMARY_PAYMENT_METHOD_INFO);

export const resetPrimaryPaymentMethodInfo: () => BaseAction = createAction(
  actionTypes.RESET_PRIMARY_PAYMENT_METHOD_INFO
);
