import { createSelector } from 'reselect';

export const convenienceFeeStateSelector = (state: IRootState) => state.convenienceFee;

export const isFetchingConvenienceFeeSelector = createSelector(
  convenienceFeeStateSelector,
  ({ isFetchingConvenienceFee }) => isFetchingConvenienceFee
);

export const isFetchedConvenienceFeeSelector = createSelector(
  convenienceFeeStateSelector,
  ({ isFetchedConvenienceFee }) => isFetchedConvenienceFee
);

export const convenienceFeeSelector = createSelector(
  convenienceFeeStateSelector,
  ({ convenienceFee }) => convenienceFee
);
