import { getOwedAmountInDollars } from 'utils/loan';
import useSelectedLoan from './useSelectedLoan';
import { useSelector } from 'react-redux';
import * as loanSelectors from 'selectors/loan';

const usePayoffAmount = () => {
  let loan = useSelectedLoan();
  const { nextScheduledPaymentDue } = useSelector((state: IRootState) => {
    const isFetched = loanSelectors.isFetchedSelector(state);
    if (isFetched && !loan) {
      loan = loanSelectors.loansSelector(state)[0];
    }

    return {
      ...(loan
        ? {
            nextScheduledPaymentDue: loanSelectors.getNextPaymentDueSelector(loan.id)(state),
          }
        : {
            nextScheduledPaymentDue: null,
          }),
    };
  });

  let isPayoffAmount = false;
  let paymentAmount: number;
  const owedAmount = getOwedAmountInDollars(nextScheduledPaymentDue);
  if (loan && loan.currentPayoffBalance && owedAmount > loan.currentPayoffBalance) {
    isPayoffAmount = true;
    paymentAmount = loan.currentPayoffBalance;
  } else {
    paymentAmount = owedAmount;
  }

  return { isPayoffAmount, paymentAmount };
};

export default usePayoffAmount;
