import React, { Dispatch, ReactElement, SetStateAction, useRef } from 'react';
import { Divider, FormControlLabel, TextFieldProps, RadioGroup, TextField, InputAdornment } from '@material-ui/core';
import classNames from 'classnames';

import { RadioBtnInput, ActionButton, WipPromotionBody } from 'components/widgets';
import { usePaymentAmountInput, useClientStorage, useSelectedLoan, useWipPromoEndDate } from 'hooks';
import { isCordovaApp } from 'utils/constants';
import { formatAmount, parseToNumber, undoFormatAmount } from 'utils/format';
import { getPaymentInputSessionKey } from 'utils/loan';
import { ActiveField } from './PaymentDetailsBody';

import './PaymentFields.scss';
import { useDispatch } from 'react-redux';
import { setSelectedPaymentFieldIndex } from 'actions/payment';

export interface IField {
  inputTitle: string;
  amount: number;
}

export interface ISubField extends IField {
  icon?: ReactElement;
}

type TProps = {
  fields: (IField & { subFields?: ISubField[] })[];
} & Omit<TextFieldProps, 'onChange'> & {
    value?: Nullable<string>;
    errorMessage?: string;
    currencySymbol: string;
    onChange?: (value: string) => void;
    activeFieldIndex: ActiveField;
    setActiveFieldIndex: Dispatch<SetStateAction<ActiveField>>;
    payoffBalance: number | undefined;
  };

const renderAmountDisplay = (currencySymbol: string, amount: number) => formatAmount(amount, { currencySymbol });

const PaymentFields = (props: TProps) => {
  const {
    fields,
    activeFieldIndex,
    setActiveFieldIndex,
    value,
    currencySymbol = '$',
    errorMessage,
    error,
    disabled,
    onChange,
    onBlur,
    onFocus,
    payoffBalance,
  } = props;
  const selectedLoan = useSelectedLoan();
  const { item: customAmountStorageItem, handleSetItem } = useClientStorage(
    'session',
    getPaymentInputSessionKey(selectedLoan)
  );
  const { state, handleOnBlur, handleOnFocus, handleOnChange, handleOnKeyDown } = usePaymentAmountInput({
    value,
    payoffBalance,
    onBlur,
    onChange,
    onFocus,
  });
  const wipEndDate = useWipPromoEndDate();
  const { internalValue, displayCurrencySymbol } = state;
  const paymentDueIndex = 0;
  const textFieldIndex = 1;
  const fullPayOffIndex = 2;
  const radioBtnRef = useRef<HTMLButtonElement>(null);

  const dispatch = useDispatch();
  const handleSetSelectedRadioBtnNum = (index: ActiveField) => {
    setActiveFieldIndex(index);
    dispatch(setSelectedPaymentFieldIndex(index));
  };
  const handleRadioBtnChangeOnInputClick = () => {
    // prevents deselection of input when clicked
    if (radioBtnRef.current && activeFieldIndex !== textFieldIndex) {
      radioBtnRef.current.click();
    }
  };

  const textFieldProps: TextFieldProps = {
    value: internalValue,
    disabled,
    onBlur: handleOnBlur,
    onFocus: handleOnFocus,
    onChange: handleOnChange,
    onKeyDown: handleOnKeyDown,
    error,
  };
  const hasInputError = error;

  return (
    <RadioGroup
      styleName="wrapper"
      aria-labelledby="payment-type-group-label"
      defaultValue={fields[paymentDueIndex].inputTitle}
      value={fields[activeFieldIndex].inputTitle}
      name="payment-type-group"
      onChange={handleOnChange}
    >
      {fields.map((field, index) => {
        const { inputTitle, amount, subFields } = field;
        const inputId = inputTitle.toLowerCase().replace(/\s+/g, '-');
        const isActiveSelectedRadioBtn = activeFieldIndex === index;
        const isTextFieldIndex = index === textFieldIndex;
        const isFullPayOffIndex = index === fullPayOffIndex;
        const isTextFieldRadioSelected = isActiveSelectedRadioBtn && isTextFieldIndex;
        const selectedAmount = isTextFieldIndex ? parseToNumber(String(customAmountStorageItem)) || 0 : amount;
        const isFullyPayoffAmount = selectedAmount === payoffBalance;

        return (
          <div key={inputTitle}>
            <div styleName="field-wrapper">
              <div styleName="input-group">
                <FormControlLabel
                  styleName="label-group"
                  label={inputTitle}
                  value={inputTitle}
                  control={
                    <RadioBtnInput
                      id={inputId}
                      // set ref only for custom field
                      ref={isTextFieldIndex ? radioBtnRef : null}
                      disabled={isTextFieldIndex && textFieldProps.disabled}
                      onChange={(e) => {
                        // change radio btn value from title to
                        // amount while keeping it in checked state
                        // passes value to radio group change handler
                        e.target.value = selectedAmount.toFixed(2);
                        handleSetSelectedRadioBtnNum(index);
                      }}
                    />
                  }
                />
                {isFullPayOffIndex && !!wipEndDate && (
                  <span styleName="label-info">
                    <ActionButton size="small" customVariant="info" onClick={undefined}>
                      Recommended
                    </ActionButton>
                  </span>
                )}
              </div>
              <div
                styleName={classNames(
                  'amount',
                  isTextFieldIndex && isTextFieldRadioSelected && 'amount-input',
                  isTextFieldIndex && !isTextFieldRadioSelected && 'amount-hide'
                )}
                // selects custom amount radio btn on input click
                onClick={isTextFieldIndex ? handleRadioBtnChangeOnInputClick : undefined}
              >
                {isTextFieldRadioSelected ? (
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                      inputMode: 'numeric',
                      startAdornment: displayCurrencySymbol ? (
                        <InputAdornment component="span" position="start">
                          {currencySymbol}
                        </InputAdornment>
                      ) : undefined,
                    }}
                    autoFocus
                    {...textFieldProps}
                    onBlur={(e) => {
                      if (textFieldProps.onBlur) {
                        textFieldProps.onBlur(e);
                      }
                      // save unformatted user input in session
                      if (isTextFieldRadioSelected) {
                        handleSetItem(undoFormatAmount(e.target.value));
                      }
                    }}
                    {...(isCordovaApp ? { type: 'number' } : undefined)}
                  />
                ) : (
                  <span>{renderAmountDisplay(currencySymbol, selectedAmount)}</span>
                )}
              </div>
            </div>

            {isTextFieldRadioSelected && hasInputError && <div styleName="error-input">{errorMessage}</div>}

            {/* shows the breakdown of payments being made */}
            {!hasInputError && !isFullyPayoffAmount && (
              <div styleName={classNames('sub-wrapper', isActiveSelectedRadioBtn && 'sub-wrapper-show')}>
                {!!subFields &&
                  !!subFields.length &&
                  subFields.map((subField) => {
                    const { inputTitle: subInputTitle, icon: Icon, amount: subAmount } = subField;
                    if (!subAmount || subAmount <= 0) return null;
                    return (
                      <div styleName="sub-field-wrapper" key={subInputTitle}>
                        <div styleName="sub-field-title">
                          <span>
                            {subInputTitle}
                            {!!Icon && ': '}
                          </span>
                          {!!Icon && <span styleName="sub-field-icon">{Icon}</span>}
                        </div>
                        <div>{renderAmountDisplay(currencySymbol, subAmount)}</div>
                      </div>
                    );
                  })}
              </div>
            )}

            {isFullPayOffIndex && (
              <div styleName="wip">
                <WipPromotionBody />
              </div>
            )}
            {fields.length - 1 !== index && <Divider />}
          </div>
        );
      })}
    </RadioGroup>
  );
};

export default PaymentFields;
