import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import * as loanSelectors from 'selectors/loan';
import * as paymentSelectors from 'selectors/payment';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import { setSelectedLoanId } from 'actions/loan';
import { useSelectedLoan, useWipPromoEndDate } from 'hooks';
import { routes } from 'utils/routeHelper';
import { buildLoanOverviewValue } from 'utils/loan';

import { Header } from 'components/layouts';
import {
  Col,
  GifAnimationWrapper,
  LoanOverviewTabs,
  LateFeeDialog,
  LoadingSpinner,
  IconWrapper,
  ActionButton,
  WipPromotionBody,
} from 'components/widgets';
import AutopayToggleWithDialog from 'components/widgets/loan/AutopayToggleWithDialog';
import { IconScratchpayHeart } from 'assets/icons';
import CallButton from '../widgets/CallButton';

import { List, ListItem } from '@material-ui/core';
import { formatAmount, formatDate } from 'utils/format';
import { DEFAULT_VALUE_MISSING_FIELDS } from 'utils/constants';
import { Footer } from 'components/layouts';

import './LoanOverview.scss';
import usePayoffAmount from 'hooks/usePayoffAmount';

export const LoanOverview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpenLateFeeDialog, setIsOpenLateFeeDialog] = useState(false);
  let loan = useSelectedLoan();
  const wipPromoEndDate = useWipPromoEndDate();
  const {
    isFetched,
    isFetching,
    isPendingLoan,
    isPaidoffLoan,
    isPayableLoan,
    nextScheduledPaymentDue,
    lateScheduledPaymentCount,
    paymentMethodCount,
    isAutopayEnabled,
    primaryPaymentMethodLast4,
  } = useSelector((state: IRootState) => {
    const isFetched = loanSelectors.isFetchedSelector(state);
    const isFetching =
      loanSelectors.isFetchingSelector(state) ||
      loanSelectors.isSynchronizingSelector(state) ||
      paymentSelectors.isFetchingPaymentsSelector(state);
    if (isFetched && !loan) {
      loan = loanSelectors.loansSelector(state)[0];
    }

    return {
      isFetched,
      isFetching,
      loan,
      paymentMethodCount: paymentMethodSelectors.paymentMethodCountSelector(state),
      ...(loan
        ? {
            isPendingLoan: loanSelectors.isPendingLoanSelector(loan.id)(state),
            isPaidoffLoan: loanSelectors.isPaidoffLoanSelector(loan.id)(state),
            isPayableLoan: loanSelectors.isPayableLoanSelector(loan.id)(state),
            nextScheduledPaymentDue: loanSelectors.getNextPaymentDueSelector(loan.id)(state),
            lateScheduledPaymentCount: paymentSelectors.loanLateScheduledPaymentCountSelector(loan.id)(state),
            isAutopayEnabled: loanSelectors.isAutopayEnabledSelector(loan.id)(state),
            primaryPaymentMethodLast4: loanSelectors.getPrimaryPaymentMethodLast4Selector(loan.id)(state),
          }
        : {
            isPendingLoan: false,
            isPaidoffLoan: false,
            isPayableLoan: false,
            nextScheduledPaymentDue: null,
            lateScheduledPaymentCount: 0,
            isAutopayEnabled: false,
            primaryPaymentMethodLast4: '',
          }),
    };
  });

  const { paymentAmount } = usePayoffAmount();

  if (!isFetched) {
    return <LoadingSpinner styleName="custom loading" />;
  }

  if (!loan) return <Redirect to={routes.HOME} />;

  const selectedLoan = loan;
  const loanId = selectedLoan.id;
  const loanOverviewValue = buildLoanOverviewValue(selectedLoan);
  const showCallPracticeToFinalizeButton =
    selectedLoan.isPending && selectedLoan.practice && selectedLoan.practice.phoneNumber;

  const shouldDisplayLateFeeDialog = lateScheduledPaymentCount > 1;
  const hasPaymentMethods = paymentMethodCount > 0;

  const goToPaymentDetails = () => {
    dispatch(setSelectedLoanId(selectedLoan.id));
    history.push(routes.PAYMENT_DETAILS);
  };

  const handleOnMakePayment = () => {
    if (nextScheduledPaymentDue && shouldDisplayLateFeeDialog) {
      setIsOpenLateFeeDialog(true);
    } else if (!hasPaymentMethods) {
      return;
    } else {
      goToPaymentDetails();
    }
  };

  const handleOnCloseLateFeeDialog = () => setIsOpenLateFeeDialog(false);

  const nextPaymentAmount = paymentAmount;
  const nextPaymentDueAt = nextScheduledPaymentDue ? nextScheduledPaymentDue.dueAt : '';
  const displayedNextPaymentAmount =
    nextPaymentAmount && !isPendingLoan ? formatAmount(nextPaymentAmount) : DEFAULT_VALUE_MISSING_FIELDS;
  const displayedNextPaymentDueAt =
    nextPaymentDueAt && !isPendingLoan
      ? formatDate(nextPaymentDueAt, { outputFormat: 'MM/DD/YY' })
      : DEFAULT_VALUE_MISSING_FIELDS;

  const renderPayoffBalance = () =>
    showCallPracticeToFinalizeButton ? (
      <CallButton
        phoneNumber={selectedLoan.practice!.phoneNumber}
        title="Call practice to finalize the plan"
        align="center"
      />
    ) : (
      loanOverviewValue.payoffBalance
    );
  const renderLateFeeNotice = () =>
    !!loanOverviewValue.lateFees ? (
      <>
        <div styleName="next-payment-heading">Late Fee: </div>
        <div styleName="next-payment-value late-fee">{loanOverviewValue.lateFees}</div>
      </>
    ) : null;
  const renderNextPayment = () => {
    if (selectedLoan.isPaidoff) {
      return null;
    }

    return (
      <Col styleName="next-payment">
        <List>
          <ListItem styleName="list-item">
            <div>
              <div styleName="next-payment-practice-name">{loanOverviewValue.practiceName}</div>
              <div styleName="next-payment-id"> Plan ID: {loanId}</div>
            </div>
            <div>
              <div styleName="next-payment-heading">Payment amount: </div>
              <div styleName="next-payment-value">{displayedNextPaymentAmount}</div>

              <div styleName="next-payment-due">
                <div>
                  <div styleName="next-payment-heading">Payment Due: </div>
                  <div styleName="next-payment-value">{displayedNextPaymentDueAt}</div>
                </div>
                <div>{renderLateFeeNotice()}</div>
              </div>
            </div>
          </ListItem>
          <ListItem styleName="list-item next-payment-payment">
            <div>
              {isPayableLoan ? (
                <ActionButton customVariant="standard" onClick={handleOnMakePayment} disabled={isFetching}>
                  Pay Now
                </ActionButton>
              ) : null}
            </div>
            <div styleName="next-payment-autopay">
              <div>
                <div>Autopay</div>
                <div styleName="next-payment-autopay-setting">
                  {isAutopayEnabled ? `On *${primaryPaymentMethodLast4}` : 'Off'}
                </div>
              </div>
              <div>
                <AutopayToggleWithDialog loan={selectedLoan} />
              </div>
            </div>
          </ListItem>
        </List>
      </Col>
    );
  };

  return (
    <>
      <div styleName="wrapper">
        <Header styleOptions={{ isSticky: false, isLight: true }} />
        <div styleName="wrapper-top">
          <Col styleName="col">
            <IconWrapper>
              <IconScratchpayHeart />
            </IconWrapper>
            <div styleName="practice-name">{loanOverviewValue.practiceName}</div>
            <div styleName="loan-id">Remaining for {loanId}</div>
            <div styleName="payoff-balance">{renderPayoffBalance()}</div>
            {renderNextPayment()}
          </Col>
          {!!wipPromoEndDate && (
            <>
              <br />
              <Col styleName="col">
                <div styleName="wip">
                  <div styleName="wip-title">Promotional Offer</div>
                  <div styleName="wip-body">
                    <WipPromotionBody />
                  </div>
                </div>
              </Col>
            </>
          )}
        </div>
        <LoanOverviewTabs
          loanId={loanId}
          isFetching={isFetching}
          isPaidoff={isPaidoffLoan}
          details={loanOverviewValue}
        />
        <Footer />
      </div>
      <LateFeeDialog isOpen={isOpenLateFeeDialog} onClose={handleOnCloseLateFeeDialog} onPayNow={goToPaymentDetails} />
      {isFetching && <GifAnimationWrapper />}
    </>
  );
};

export default LoanOverview;
