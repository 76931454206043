import { createSelector } from 'reselect';

export const userSelector = (state: IRootState) => state.user;

export const borrowerSelector = createSelector(userSelector, ({ borrower }) => borrower);

export const isCreatingApplicationUrlSelector = createSelector(
  userSelector,
  ({ isCreatingApplicationUrl }) => isCreatingApplicationUrl
);

export const isRenewingBorrowerSelector = createSelector(userSelector, ({ isRenewingBorrower }) => isRenewingBorrower);

export const signInSuccessUrlSelector = createSelector(userSelector, ({ signInSuccessUrl }) => signInSuccessUrl);

export const getBorrowerAddressStateSelector = createSelector(borrowerSelector, (borrower) => {
  if (!borrower) {
    return '';
  }
  return borrower.state;
});
