import React, { Component } from 'react';
import { Moment } from 'moment';
import { isUndefined } from 'lodash';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import { DATE_FORMATS } from 'utils/format';

import './DatePickerField.scss';

const DEFAULT_FORMAT = DATE_FORMATS.UTC_DATE;

const EXPORTED_VALUE_TYPES = {
  MOMENT: 'moment',
  STRING: 'string',
};

export type ExportedValueType = Nullable<Moment> | Nullable<string>;

interface IProps {
  label?: React.ReactNode;
  value?: Nullable<string | Moment>;
  format?: string;
  placeholder?: string;
  exportedType?: 'moment' | 'string';
  views?: ('date' | 'month' | 'year')[];
  minDate?: Moment | Date;
  minDateMessage?: string;
  maxDate?: Moment | Date;
  maxDateMessage?: string;
  helperText?: Nullable<string>;
  error?: boolean;
  onChange?: (value: ExportedValueType) => void;
}

class DatePickerField extends Component<IProps, {}> {
  formatExportedValue = (date: Nullable<Moment>): ExportedValueType => {
    if (!date) return null;

    const { exportedType } = this.props;
    switch (exportedType) {
      case EXPORTED_VALUE_TYPES.MOMENT:
        return date;
      case EXPORTED_VALUE_TYPES.STRING:
      default:
        return date.format(DEFAULT_FORMAT);
    }
  };

  onChange = (date: Nullable<Moment>): void => {
    if (this.props.onChange) {
      const exportedValue = this.formatExportedValue(date);
      this.props.onChange(exportedValue);
    }
  };

  render() {
    const {
      label,
      value,
      placeholder,
      format = DEFAULT_FORMAT,
      views,
      minDate,
      minDateMessage,
      maxDate,
      maxDateMessage,
      helperText,
      error,
    } = this.props;

    const errorProps = isUndefined(error) ? {} : { helperText, error };

    return (
      <div styleName="wrapper">
        <KeyboardDateTimePicker
          animateYearScrolling
          inputVariant="outlined"
          label={label}
          value={value}
          format={format}
          views={views}
          placeholder={placeholder || format.toLowerCase()}
          minDate={minDate} // default from lib will be 1900-01-01
          minDateMessage={minDateMessage}
          maxDate={maxDate} // default from lib will be 2100-01-01
          maxDateMessage={maxDateMessage}
          {...errorProps}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default DatePickerField;
