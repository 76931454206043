import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { History, Location } from 'history';
import { isNil } from 'lodash';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { routes } from 'utils/routeHelper';
import { REGEXES } from 'utils/validate';
import { makeRawPhoneNumber, formatPhoneNumber } from 'utils/format';
import {
  isCordovaApp,
  SCRATCHPAY_URL,
  AUTH_METHOD_SMS,
  AUTH_METHOD_VOICE_CALL,
  SUPPORT_CONTACT,
} from 'utils/constants';
import { showNotification } from 'utils/notification';
import firebaseClient from 'utils/firebase';
import {
  getAuth,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithCredential,
  signInWithPhoneNumber,
  signInWithCustomToken,
  signOut,
} from 'firebase/auth';

import { Col, Fab, TextInput, KeyboardWrapper, LoadingSpinner } from 'components/widgets';
import { Header } from 'components/layouts';
import { IconScratchpayHeart } from 'assets/icons';
import { LogoScratchpayTextOnlyWhite } from 'assets/images';
import { signInSuccessUrlSelector } from 'selectors/user';
import { setSignInSuccessUrl } from 'actions/user';
import { sendVoiceOTP, verifyVoiceOTP } from 'apis';
import './OTPConfirmation.scss';
import { FirebaseError } from 'firebase/app';

const RECAPTCHA_CONTAINER_ID = 'recaptcha-container';
const HIDE_RESEND_BUTTON_TIMEOUT = 60 * 1000; // 60 seconds
const ERROR_TYPES = {
  EXCEED_ATTEMPTS: 'EXCEED_ATTEMPTS',
  INVALID_PHONE: 'INVALID_PHONE',
  INVALID_OTP: 'INVALID_OTP',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

interface ICredentialsCordova {
  verificationId: string;
}

interface IProps {
  history: History;
  location: Location;
}

const OTPConfirmation = (props: IProps) => {
  const dispatch = useDispatch();
  const timeoutIdRef = useRef<any>();
  const auth = getAuth(firebaseClient);
  if (process.env.DEPLOYMENT_ENV === 'staging') {
    auth.settings.appVerificationDisabledForTesting = true;
  }

  const recaptchaVerifier = useRef<RecaptchaVerifier>();
  const signInSuccessUrl = useSelector(signInSuccessUrlSelector);
  const [authMethod, setAuthMethod] = useState<string>(AUTH_METHOD_SMS);

  const [otp, setOTP] = useState<string>('');
  const [resendButtonStates, setResendButtonStates] = useState<{ isDisplayed: boolean; isDisabled: boolean }>({
    isDisplayed: true,
    isDisabled: false,
  });
  const [callMeInsteadButtonStates, setCallMeInsteadButtonStates] = useState<{
    isDisplayed: boolean;
    isDisabled: boolean;
  }>({
    isDisplayed: true,
    isDisabled: false,
  });
  const [errorType, setErrorType] = useState<Nullable<string>>(null);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [verificationId, setVerificationId] = useState<Nullable<string>>(null);
  const [isSendingOTP, setIsSendingOTP] = useState<boolean>(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isLoadingRecaptcha, setIsLoadingRecaptcha] = useState<boolean>(false);

  const maxOTPLength = useMemo(() => {
    if (authMethod === AUTH_METHOD_SMS) {
      return 6;
    }
    return 4;
  }, [authMethod]);

  const isConfirmOTPButtonDisabled = isSendingOTP || isConfirming || !verificationId || otp.length !== maxOTPLength;
  const isMissingPhoneNumberInfo =
    isNil(props.location) ||
    isNil(props.location.state) ||
    !props.location.state.areaCode ||
    !props.location.state.phoneNumber;

  useEffect(() => {
    if (!isMissingPhoneNumberInfo) {
      if (!isCordovaApp) {
        setIsLoadingRecaptcha(true);
        recaptchaVerifier.current = new RecaptchaVerifier(auth, RECAPTCHA_CONTAINER_ID, {
          size: 'invisible',
          callback: () => setIsLoadingRecaptcha(false),
        });
      }
      sendOTP();
    }
  }, []);

  const showErrorNotification = () => {
    showNotification("We're unable to verify the confirmation code", { variant: 'error' });
  };

  const sendOTP = async () => {
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      setResendButtonStates({
        isDisplayed: true,
        isDisabled: false,
      });
      setCallMeInsteadButtonStates({
        isDisplayed: true,
        isDisabled: false,
      });
    }, HIDE_RESEND_BUTTON_TIMEOUT);

    const { areaCode, phoneNumber } = props.location.state;
    const fullPhoneNumber = `${areaCode}${makeRawPhoneNumber(phoneNumber)}`;

    try {
      if (isCordovaApp) {
        window.FirebasePlugin.verifyPhoneNumber(
          (credential: ICredentialsCordova) => {
            setVerificationId(credential.verificationId);
            setIsSendingOTP(false);
          },
          (errorMessage: string) => {
            setIsSendingOTP(false);
            handleErrorMessage(errorMessage);
          },
          fullPhoneNumber,
          60
        );
      } else if (recaptchaVerifier.current) {
        const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, recaptchaVerifier.current);
        setVerificationId(confirmationResult.verificationId);
        setIsSendingOTP(false);
      }
    } catch (error) {
      setIsSendingOTP(false);
      handleErrorMessage(error);
    }
  };

  const callMeInsteadWithOTP = async () => {
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      setResendButtonStates({
        isDisplayed: true,
        isDisabled: false,
      });
      setCallMeInsteadButtonStates({
        isDisplayed: true,
        isDisabled: false,
      });
    }, HIDE_RESEND_BUTTON_TIMEOUT);

    try {
      const { phoneNumber } = props.location.state;
      const rawPhoneNumber = `1${makeRawPhoneNumber(phoneNumber)}`;

      await sendVoiceOTP(rawPhoneNumber);
      setIsSendingOTP(false);
    } catch (error) {
      setIsSendingOTP(false);
      handleErrorMessage(error);
    }
  };

  const handleOnResend = () => {
    setAuthMethod(AUTH_METHOD_SMS);
    setIsSendingOTP(true);
    setResendButtonStates({
      isDisplayed: true,
      isDisabled: true,
    });
    setCallMeInsteadButtonStates({
      isDisplayed: true,
      isDisabled: true,
    });
    setErrorType(null);
    setErrorMessage(null);
    setOTP('');

    sendOTP();
  };

  const handleOnCallMeInstead = () => {
    setAuthMethod(AUTH_METHOD_VOICE_CALL);
    setIsSendingOTP(true);
    setResendButtonStates({
      isDisplayed: true,
      isDisabled: true,
    });
    setCallMeInsteadButtonStates({
      isDisplayed: true,
      isDisabled: true,
    });
    setErrorType(null);
    setErrorMessage(null);
    setOTP('');

    callMeInsteadWithOTP();
  };

  const handleOnChangeOTP = (updatedOTP: string) => {
    if (REGEXES.NUMBER_ONLY.test(updatedOTP)) {
      setErrorType(null);
      setErrorMessage(null);
      setOTP(updatedOTP);
    }
  };

  const handleOnFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleOnConfirmOTP();
  };

  const resetSignInSuccessUrl = () => dispatch(setSignInSuccessUrl(''));

  const onConfirmSMS = async () => {
    try {
      if (!verificationId) throw new Error();

      const phoneCredential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, phoneCredential);
      const { currentUser } = auth;

      if (!currentUser) throw new Error();

      clearTimeout(timeoutIdRef.current);
      if (signInSuccessUrl) {
        props.history.push(signInSuccessUrl);
        resetSignInSuccessUrl();
      } else {
        props.history.push(routes.HOME);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  const onConfirmVoiceCall = async () => {
    try {
      const { phoneNumber } = props.location.state;
      const rawPhoneNumber = `1${makeRawPhoneNumber(phoneNumber)}`;

      const { token } = await verifyVoiceOTP(rawPhoneNumber, otp);
      await signInWithCustomToken(auth, token!);
      clearTimeout(timeoutIdRef.current);
      if (signInSuccessUrl) {
        props.history.push(signInSuccessUrl);
        resetSignInSuccessUrl();
      } else {
        props.history.push(routes.HOME);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  };
  const handleOnConfirmOTP = async () => {
    if (isConfirmOTPButtonDisabled) {
      return;
    }
    setIsConfirming(true);
    if (authMethod === AUTH_METHOD_SMS) {
      return onConfirmSMS();
    }
    return onConfirmVoiceCall();
  };

  const handleErrorMessage = async (error: any) => {
    showErrorNotification();

    const { currentUser } = auth;
    if (currentUser) {
      await signOut(auth);
      return;
    }

    setIsConfirming(false);
    if (error instanceof FirebaseError) {
      if (error.code === 'auth/invalid-verification-code') {
        setErrorType(ERROR_TYPES.INVALID_OTP);
      } else if (error.code === 'auth/too-many-requests') {
        setErrorType(ERROR_TYPES.EXCEED_ATTEMPTS);
      } else if (error.code === 'auth/invalid-phone-number' || error.code === 'auth/user-not-found') {
        setErrorType(ERROR_TYPES.INVALID_PHONE);
      } else {
        setErrorType(ERROR_TYPES.UNKNOWN_ERROR);
      }
    } else if (error.isAxiosError) {
      if (error.response) {
        setErrorMessage(error.response.data.message);
      } else if (error.message) {
        setErrorMessage(error.message);
      } else {
        setErrorType(ERROR_TYPES.UNKNOWN_ERROR);
      }
    } else {
      setErrorType(ERROR_TYPES.UNKNOWN_ERROR);
    }
  };

  const renderErrorMessage = () => {
    let errorMessageToShow;
    let addDetailedMessage: JSX.Element | undefined;

    switch (errorType) {
      case ERROR_TYPES.INVALID_PHONE:
        return (
          <div styleName="error-message">
            {`We couldn't locate your account. To proceed, either reconfirm your phone number or start a new application at `}
            <a href={SCRATCHPAY_URL}>scratchpay.com</a>
            {`. If you think this is an error, please reach out to our Support Team for assistance.`}
          </div>
        );
      case ERROR_TYPES.EXCEED_ATTEMPTS:
        errorMessageToShow = `Uh-oh! Too many failed attempts. Need help? Call us at ${SUPPORT_CONTACT.PHONE_NUMBER_FORMATTED}.`;
        break;
      case ERROR_TYPES.INVALID_OTP:
        errorMessageToShow = `The confirmation code you've entered is invalid. Please try again.`;
        break;
      default:
        addDetailedMessage = errorMessage ? (
          <>
            <br />
            {`-- ${errorMessage}`}
          </>
        ) : undefined;
        errorMessageToShow = `We are having technical difficulties.  Please try again.`;
        break;
    }
    return (
      <div styleName="error-message">
        {errorMessageToShow}
        {addDetailedMessage}
      </div>
    );
  };

  const handleOnBackToLoginPage = () => {
    props.history.replace({
      pathname: routes.LOGIN,
      state: props.location.state,
    });
  };

  if (isMissingPhoneNumberInfo) {
    return <Redirect to={routes.LOGIN} />;
  }

  const { areaCode, phoneNumber } = props.location.state;
  const displayedPhoneNumber = formatPhoneNumber(areaCode, phoneNumber);
  const hasError = !!errorType || !!errorMessage;

  return (
    <div styleName="wrapper">
      <Header onBack={handleOnBackToLoginPage} styleOptions={{ isDarkBlue: true }} />
      <div styleName="wrapper-logo">
        <IconScratchpayHeart styleName="icon heart" />
        <img src={LogoScratchpayTextOnlyWhite} alt="logo-scratchpay" styleName="logo" />
      </div>
      <Col xs={12} sm={6} md={6}>
        {isLoadingRecaptcha ? (
          <LoadingSpinner />
        ) : (
          <KeyboardWrapper>
            <form onSubmit={handleOnFormSubmit}>
              <TextInput
                isWhiteStyle
                error={hasError}
                label="Confirmation code"
                type="tel"
                autoComplete="one-time-code"
                inputProps={{
                  maxLength: maxOTPLength,
                  name: 'one-time-code',
                  autoComplete: 'one-time-code',
                }}
                value={otp}
                onChange={handleOnChangeOTP}
              />
            </form>
            {(errorType || errorMessage) && renderErrorMessage()}
            <p styleName="disclaimer">{`Enter the confirmation code we have sent to ${displayedPhoneNumber}`}</p>
            {resendButtonStates.isDisplayed && (
              <div styleName="wrapper-btn-resend">
                <Tooltip
                  title={
                    resendButtonStates.isDisabled
                      ? 'The confirmation code has already been resent !'
                      : 'Haven’t received the code yet. Request again !'
                  }
                >
                  <span style={{ cursor: resendButtonStates.isDisabled ? 'not-allowed' : 'pointer' }}>
                    <Button
                      type="button"
                      styleName="btn resend"
                      disabled={resendButtonStates.isDisabled}
                      onClick={handleOnResend}
                    >
                      Resend
                    </Button>
                  </span>
                </Tooltip>
              </div>
            )}
            {callMeInsteadButtonStates.isDisplayed && (
              <div styleName="wrapper-btn-resend">
                <Tooltip
                  title={
                    callMeInsteadButtonStates.isDisabled
                      ? 'The confirmation code has already been resent !'
                      : `Haven't received the code yet. Request again over call !`
                  }
                >
                  <span style={{ cursor: callMeInsteadButtonStates.isDisabled ? 'not-allowed' : 'pointer' }}>
                    <Button
                      type="button"
                      styleName="btn resend"
                      disabled={callMeInsteadButtonStates.isDisabled}
                      onClick={handleOnCallMeInstead}
                    >
                      Or Call me instead
                    </Button>
                  </span>
                </Tooltip>
              </div>
            )}
            <Fab disabled={isConfirmOTPButtonDisabled} onClick={handleOnConfirmOTP}>
              {isConfirming ? 'Confirming...' : 'Confirm & log in'}
            </Fab>
          </KeyboardWrapper>
        )}
        <div id={RECAPTCHA_CONTAINER_ID} />
      </Col>
    </div>
  );
};

export default OTPConfirmation;
