import React from 'react';
import { PlaidLink, PlaidLinkOnSuccessMetadata } from 'react-plaid-link';

import { IAddPaymentMethodStripeACHData } from 'actions/paymentMethod';
import { PAYMENT_METHOD_TYPES, PAYMENT_METHOD_VENDORS } from 'utils/constants';

interface IProps {
  token: Nullable<string>;
  onAddedSuccess: (result: IAddPaymentMethodStripeACHData) => void;
  onExit: () => void;
}

const PlaidACHForm = (props: IProps) => {
  const onSuccess = (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
    const { accounts, institution } = metadata;

    // link_customization_name is "default" which only allows 1 account to be selected
    const result = {
      vendor: PAYMENT_METHOD_VENDORS.STRIPE,
      paymentMethod: PAYMENT_METHOD_TYPES.ACH,
      plaidPublicToken: publicToken,
      plaidAccountId: accounts[0].id,
      last4: accounts[0].mask,
      name: accounts[0].name,
      type: accounts[0].subtype,
      bankName: institution && institution.name,
    };
    props.onAddedSuccess(result);
  };

  if (!props.token) {
    return <span>ACH</span>;
  }

  return (
    <PlaidLink token={props.token} onSuccess={onSuccess} onExit={props.onExit}>
      ACH
    </PlaidLink>
  );
};
export default PlaidACHForm;
