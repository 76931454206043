import { createAction, Action, BaseAction } from 'redux-actions';

export const actionTypes = {
  GET_PAYMENTS: 'GET_PAYMENTS',
  GET_PAYMENTS_REQUEST: 'GET_PAYMENTS_REQUEST',
  GET_PAYMENTS_SUCCESS: 'GET_PAYMENTS_SUCCESS',
  GET_PAYMENTS_ERROR: 'GET_PAYMENTS_ERROR',

  GET_SCHEDULED_PAYMENTS_SUCCESS: 'GET_SCHEDULED_PAYMENTS_SUCCESS',
  GET_LOAN_SCHEDULED_PAYMENTS_SUCCESS: 'GET_LOAN_SCHEDULED_PAYMENTS_SUCCESS',
  REMOVE_LOAN_SCHEDULED_PAYMENTS_SUCCESS: 'REMOVE_LOAN_SCHEDULED_PAYMENTS_SUCCESS',

  SELECT_PAYMENT: 'SELECT_PAYMENT',

  MAKE_PAYMENT: 'MAKE_PAYMENT',
  SEND_RECEIPT: 'SEND_RECEIPT',

  SET_SELECTED_PAYMENT_FIELD_INDEX: 'SET_SELECTED_PAYMENT_FIELD_INDEX',
};

export interface IMakePaymentData {
  loanId: string;
  tokenId: string;
  amount?: number;
  channel: string;
  waiveConvenienceFee?: boolean;
  isSACPayoffPayment?: boolean;
}

interface IMakePaymentPayload {
  data: IMakePaymentData;
  onSuccess: () => void;
  onError?: (errorMessage: string) => void;
}

export interface ISendReceiptData {
  emails: string[];
  dataUri: string;
}

interface ISendReceiptPayload {
  data: ISendReceiptData;
  onSuccess: () => void;
  onError: () => void;
}

export const getPayments: () => BaseAction = createAction(actionTypes.GET_PAYMENTS);

export const getPaymentsRequest: () => BaseAction = createAction(actionTypes.GET_PAYMENTS_REQUEST);

export const getPaymentsSuccess: (receipts: IPayment[]) => Action<IPayment[]> = createAction(
  actionTypes.GET_PAYMENTS_SUCCESS
);

export const getPaymentsError: () => Action<void> = createAction(actionTypes.GET_PAYMENTS_ERROR);

export const getScheduledPaymentsSuccess: (
  scheduledPayments: IScheduledPayment[]
) => Action<IScheduledPayment[]> = createAction(actionTypes.GET_SCHEDULED_PAYMENTS_SUCCESS);

export const getLoanScheduledPaymentsSuccess: (
  scheduledPayments: IScheduledPayment[]
) => Action<IScheduledPayment[]> = createAction(actionTypes.GET_LOAN_SCHEDULED_PAYMENTS_SUCCESS);

export const removeLoanScheduledPaymentsSuccess: (
  scheduledPayments: IScheduledPayment[]
) => Action<IScheduledPayment[]> = createAction(actionTypes.REMOVE_LOAN_SCHEDULED_PAYMENTS_SUCCESS);

export const selectPayment: (paymentId: string | number) => Action<string | number> = createAction(
  actionTypes.SELECT_PAYMENT
);

export const makePayment: (payload: IMakePaymentPayload) => Action<IMakePaymentPayload> = createAction(
  actionTypes.MAKE_PAYMENT
);

export const sendReceipt: (payload: ISendReceiptPayload) => Action<ISendReceiptPayload> = createAction(
  actionTypes.SEND_RECEIPT
);

export const setSelectedPaymentFieldIndex: (idx: number) => Action<number> = createAction(
  actionTypes.SET_SELECTED_PAYMENT_FIELD_INDEX
);
