import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getPayments } from 'actions/payment';
import firebaseClient from 'utils/firebase';
import { getAuth } from 'firebase/auth';

const usePaymentFetcher = () => {
  const dispatch = useDispatch();
  const { currentUser } = getAuth(firebaseClient);

  const fetchPayments = () => dispatch(getPayments());

  useEffect(() => {
    if (currentUser) {
      fetchPayments();
    }
  }, [currentUser]);

  return { fetchPayments };
};

export default usePaymentFetcher;
