import React from 'react';

import { HomeHeader } from 'components/layouts';

import './HomePageLayout.scss';

interface IProps {
  children?: React.ReactNode;
}

const HomePageLayout = (props: IProps) => {
  const { children } = props;

  return (
    <div styleName="container">
      <HomeHeader />
      {children}
    </div>
  );
};

export default HomePageLayout;
