import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { IconLink } from 'components/widgets';
import { IconUserAccountFaqs, IconUserAccountSupportEmail, IconUserAccountSupport } from 'assets/icons';

import './Footer.scss';
import { SCRATCHPAY_FAQS_URL, SCRATCHPAY_SUPPORT_PHONE_NUM, SCRATCHPAY_SUPPORT_EMAIL } from 'utils/constants';

const Footer = () => {
  return (
    <Box styleName="wrapper">
      <div styleName="help">Need help?</div>
      <div styleName="links">
        <div styleName="link">
          <IconLink isExternal path={SCRATCHPAY_FAQS_URL} text="Read FAQs" icon={<IconUserAccountFaqs />} />
        </div>
        <Divider orientation="vertical" styleName="divider" flexItem />
        <div styleName="link">
          <IconLink
            isExternal
            path={`tel:${SCRATCHPAY_SUPPORT_PHONE_NUM}`}
            text="Call Support"
            icon={<IconUserAccountSupport />}
          />
        </div>
        <Divider orientation="vertical" styleName="divider" flexItem />
        <div styleName="link">
          <IconLink
            isExternal
            path={`mailto:${SCRATCHPAY_SUPPORT_EMAIL}`}
            text="Email Support"
            icon={<IconUserAccountSupportEmail />}
          />
        </div>
      </div>
      <div styleName="webbank-language">
        Scratch Plans originated in the United States are issued by WebBank. Scratch Plans originated in Canada are
        issued by ©Scratch Financial, Inc. ©Scratchpay (NMLS ID#: 1582666). Scratch Plans are loan products subject to
        eligibility. NMLS Consumer Access
      </div>
    </Box>
  );
};

export default Footer;
