import React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { Dialog, Button } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { ButtonProps } from '@material-ui/core/Button';

import './Dialog.scss';

interface IDialogAction {
  text: string;
  type?: 'positive' | 'negative';
  disabled?: boolean;
  onClick?: DialogProps['onClick'] | DialogProps['onClose'];
}

export type Props = Omit<DialogProps, 'open'> & {
  isOpen: boolean;
  actions: IDialogAction[];
};

const CustomDialog = (props: Props) => {
  const { isOpen, title, children, actions, onClose } = props;

  return (
    <Dialog fullScreen open={isOpen} onClose={onClose}>
      <div styleName="wrapper">
        <div styleName="title">{title}</div>
        <div styleName="wrapper-content">{children}</div>
        {!isEmpty(actions) && (
          <div styleName="wrapper-actions">
            {actions.map((action: IDialogAction) => (
              <Button
                key={action.text}
                styleName={classNames('btn action', {
                  negative: action.type === 'negative',
                })}
                onClick={action.onClick as ButtonProps['onClick']}
                disabled={action.disabled || false}
              >
                {action.text}
              </Button>
            ))}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default CustomDialog;
