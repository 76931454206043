import React from 'react';
import './AutopayActivationLabel.scss';

export interface IAutopayActivationLabelProps {
  label: string;
  value: string | number | undefined;
}

const AutopayActivationLabel = (props: IAutopayActivationLabelProps) => {
  let { label, value } = props;
  if (!value) {
    value = 'N/A';
  }
  label += ':';

  return (
    <div styleName="wrapper">
      <strong>{label}</strong>
      <strong>{value}</strong>
    </div>
  );
};

export default AutopayActivationLabel;
