import { createSelector } from 'reselect';
import { chain } from 'lodash';

import { CHOSEN_PAYMENT_OPTIONS } from 'utils/constants';

const checkIsErrorLoan = (loan?: ILoan) => !!loan && loan.cannotFetchDetails;
const checkIsPendingLoan = (loan?: ILoan) =>
  !!loan && !loan.cannotFetchDetails && !loan.isPaidoff && loan.isPending === true;
const checkPayableLoan = (loan?: ILoan) =>
  !!loan && !loan.cannotFetchDetails && loan.isPending === false && loan.isPaidoff === false;
const checkIsPaidoffLoan = (loan?: ILoan) => !!loan && loan.isPaidoff === true;
const checkIsTake5Loan = (loan?: ILoan) => !!loan && loan.chosenPaymentOption === CHOSEN_PAYMENT_OPTIONS.TAKE_FIVE;
const filterByPaymentDuesStatus = (status: 'DUE' | 'PAID', loan?: ILoan) =>
  !!loan && loan.paymentDues ? loan.paymentDues.filter((paymentDue) => paymentDue.status === status) : null;
const getNextPaymentDue = (loan?: ILoan) =>
  !!loan && loan.paymentDues ? loan.paymentDues.find((paymentDue) => paymentDue.status === 'DUE') : null;
const checkIsAutopayEnabled = (loan?: ILoan) => !!loan && loan.hasScheduledPayments;
const getPrimaryPaymentMethodLast4 = (loan?: ILoan) =>
  !!loan && loan.primaryPaymentMethod ? loan.primaryPaymentMethod.last4 : '****';
const checkIsDelinquentLoan = (loan?: ILoan) => !!loan && !!loan.dueDetails && loan.dueDetails.delinquentAmount > 0;

export const loanStateSelector = (state: IRootState) => state.loan;

export const loanByIdSelector = createSelector(loanStateSelector, ({ loanById }) => loanById);
export const loanIdsSelector = createSelector(loanStateSelector, ({ loanIds }) => loanIds);
export const loansSelector = createSelector(loanStateSelector, ({ loanById, loanIds }) =>
  chain(loanIds)
    .map((id) => loanById[id])
    .filter((loan) => !!loan)
    .orderBy(({ createdAt }) => createdAt, 'desc')
    .value(),
);
export const loanCountSelector = createSelector(loanIdsSelector, (loanIds) => loanIds.length);

export const errorLoansSelector = createSelector(loansSelector, (loans) => loans.filter(checkIsErrorLoan));
export const nonErrorLoansSelector = createSelector(loansSelector, (loans) =>
  loans.filter((loan) => !checkIsErrorLoan(loan)),
);
export const pendingLoansSelector = createSelector(nonErrorLoansSelector, (loans) => loans.filter(checkIsPendingLoan));
export const paidoffLoansSelector = createSelector(nonErrorLoansSelector, (loans) => loans.filter(checkIsPaidoffLoan));
export const payableLoansSelector = createSelector(nonErrorLoansSelector, (loans) => loans.filter(checkPayableLoan));
export const payableLoanByIdSelector = createSelector(payableLoansSelector, (loans) =>
  chain(loans)
    .keyBy(({ id }) => id)
    .mapValues((loan) => loan)
    .value(),
);
export const latestPayableLoanSelector = createSelector(payableLoansSelector, (loans) => loans[0]);
export const latestPayableLoanIdSelector = createSelector(latestPayableLoanSelector, (loan) =>
  loan ? loan.id : undefined,
);
export const hasDelinquentLoan = createSelector(payableLoansSelector, (loans) => loans.some(checkIsDelinquentLoan));

export const isFetchingSelector = createSelector(loanStateSelector, ({ isFetching }) => isFetching);
export const isFetchedSelector = createSelector(loanStateSelector, ({ isFetched }) => isFetched);

export const isSynchronizingSelector = createSelector(loanStateSelector, ({ isSynchronizing }) => isSynchronizing);
export const isTogglingAutopaySelector = (loanId: string) =>
  createSelector(loanStateSelector, ({ isTogglingAutopayById }) => !!isTogglingAutopayById[loanId]);
export const isSettingPrimaryPaymentMethodSelector = (loanId: string) =>
  createSelector(
    loanStateSelector,
    ({ isSettingPrimaryPaymentMethodById }) => !!isSettingPrimaryPaymentMethodById[loanId],
  );

export const isPendingLoanSelector = (loanId: string) =>
  createSelector(loanByIdSelector, (loanById) => checkIsPendingLoan(loanById[loanId]));
export const isPaidoffLoanSelector = (loanId: string) =>
  createSelector(loanByIdSelector, (loanById) => checkIsPaidoffLoan(loanById[loanId]));
export const isTake5LoanSelector = (loanId: string) =>
  createSelector(loanByIdSelector, (loanById) => checkIsTake5Loan(loanById[loanId]));
export const isPayableLoanSelector = (loanId: string) =>
  createSelector(loanByIdSelector, (loanById) => checkPayableLoan(loanById[loanId]));

export const selectedLoanIdSelector = createSelector(loanStateSelector, ({ selectedLoanId }) => selectedLoanId);

export const selectedLoanSelector = createSelector(loanStateSelector, ({ selectedLoanId, loanById }) => {
  return selectedLoanId ? loanById[selectedLoanId] : null;
});

export const paymentDuesByStatusSelector = (loanId: string, status: 'DUE' | 'PAID') =>
  createSelector(loanByIdSelector, (loanById) => filterByPaymentDuesStatus(status, loanById[loanId]));

export const getNextPaymentDueSelector = (loanId: string) =>
  createSelector(loanByIdSelector, (loanById) => getNextPaymentDue(loanById[loanId]));
export const isAutopayEnabledSelector = (loanId: string) =>
  createSelector(loanByIdSelector, (loanById) => checkIsAutopayEnabled(loanById[loanId]));
export const getPrimaryPaymentMethodLast4Selector = (loanId: string) =>
  createSelector(loanByIdSelector, (loanById) => getPrimaryPaymentMethodLast4(loanById[loanId]));
