import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getPaymentMethods } from 'actions/paymentMethod';
import firebaseClient from 'utils/firebase';
import { getAuth } from 'firebase/auth';

const usePaymentMethodFetcher = () => {
  const dispatch = useDispatch();
  const { currentUser } = getAuth(firebaseClient);

  const fetchPaymentMethods = () => dispatch(getPaymentMethods());

  useEffect(() => {
    if (currentUser) {
      fetchPaymentMethods();
    }
  }, [currentUser]);

  return { fetchPaymentMethods };
};

export default usePaymentMethodFetcher;
