import { useDispatch, useSelector } from 'react-redux';

import * as userAction from 'actions/user';
import { isCreatingApplicationUrlSelector } from 'selectors/user';

const useApplicationUrlCreator = () => {
  const dispatch = useDispatch();
  const isCreatingApplicationUrl = useSelector(isCreatingApplicationUrlSelector);

  const createApplicationUrl = () => {
    if (!isCreatingApplicationUrl) {
      dispatch(userAction.createApplicationUrl());
    }
  };

  return { isCreatingApplicationUrl, createApplicationUrl };
};

export default useApplicationUrlCreator;
