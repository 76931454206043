import React, { Ref, forwardRef } from 'react';
import { RadioProps, Radio } from '@material-ui/core';

import './RadioBtnInput.scss';

interface IProps extends RadioProps {}

const RadioBtnInput = forwardRef((props: IProps, ref: Ref<HTMLButtonElement>) => {
  const { size = 'small', ...restProps } = props;
  return <Radio ref={ref} size={size} styleName="wrapper" {...restProps} />;
});

export default RadioBtnInput;
