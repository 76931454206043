import { isNil } from 'lodash';

import { formatAmount, getAmountInDollars } from 'utils/format';
import {
  DEFAULT_VALUE_MISSING_FIELDS,
  DEFAULT_VALUE_PENDING_LOAN_FIELDS,
  CHOSEN_PAYMENT_OPTIONS,
  CANNOT_FETCH_DATA_ERROR_MESSAGE,
  CLIENT_STORAGE_KEY,
  DEFAULT_CURRENCY_UNIT,
} from 'utils/constants';

export interface ILoanOverviewValue {
  annualPercentageRate?: string;
  finalizedAmount: string;
  regularAmount: string;
  frequency: string;
  payoffBalance: string;
  principalBalance: string;
  planTerm: number | string;
  practiceName: string;
  lateFees?: string;
}

export const formatLoanOverviewAmountValue = (input: {
  cannotFetchDetails?: boolean;
  isPending?: boolean;
  amount?: number;
}): string => {
  if (input.cannotFetchDetails) {
    return CANNOT_FETCH_DATA_ERROR_MESSAGE;
  }
  if (input.isPending) {
    return DEFAULT_VALUE_PENDING_LOAN_FIELDS;
  }
  return isNil(input.amount) ? DEFAULT_VALUE_MISSING_FIELDS : formatAmount(input.amount);
};

const getLoanOverviewDefaultValue = (input: { cannotFetchDetails?: boolean; isPending?: boolean }): string => {
  if (input.cannotFetchDetails) {
    return CANNOT_FETCH_DATA_ERROR_MESSAGE;
  }
  return input.isPending ? DEFAULT_VALUE_PENDING_LOAN_FIELDS : DEFAULT_VALUE_MISSING_FIELDS;
};

export const buildLoanOverviewValue = (loan: ILoan): ILoanOverviewValue => {
  const {
    currentInterestRate,
    currentPrincipalBalance,
    currentPayoffBalance,
    finalizedAmount,
    regularAmount,
    planTerm,
    frequency,
    isPending,
    chosenPaymentOption,
    cannotFetchDetails,
    practice,
    currentLateFeesPaymentAmountNeedToPay,
  } = loan;
  const defaultValue = getLoanOverviewDefaultValue({ cannotFetchDetails, isPending });
  const frequencyValue = frequency || defaultValue;
  const planTermValue = planTerm || defaultValue;
  const annualPercentageRateValue =
    chosenPaymentOption === CHOSEN_PAYMENT_OPTIONS.TAKE_FIVE
      ? undefined
      : isNil(currentInterestRate)
      ? defaultValue
      : `${currentInterestRate} %`;
  const practiceNameValue = practice ? practice.name : DEFAULT_VALUE_MISSING_FIELDS;

  const finalizedAmountValue = formatLoanOverviewAmountValue({
    cannotFetchDetails,
    isPending,
    amount: finalizedAmount,
  });
  const regularAmountValue = formatLoanOverviewAmountValue({
    cannotFetchDetails,
    isPending,
    amount: regularAmount,
  });
  const payoffBalanceValue = formatLoanOverviewAmountValue({
    cannotFetchDetails,
    isPending,
    amount: currentPayoffBalance,
  });
  const principalBalanceValue = formatLoanOverviewAmountValue({
    cannotFetchDetails,
    isPending,
    amount: currentPrincipalBalance,
  });
  const lateFeesValue = currentLateFeesPaymentAmountNeedToPay
    ? formatAmount(currentLateFeesPaymentAmountNeedToPay)
    : undefined;

  return {
    annualPercentageRate: annualPercentageRateValue,
    finalizedAmount: finalizedAmountValue,
    regularAmount: regularAmountValue,
    frequency: frequencyValue,
    payoffBalance: payoffBalanceValue,
    principalBalance: principalBalanceValue,
    planTerm: planTermValue,
    practiceName: practiceNameValue,
    lateFees: lateFeesValue,
  };
};

export const checkCanEnableAutopay = (input: {
  loan?: ILoan;
  payixPaymentMethodCount: number;
  stripePaymentMethodCount: number;
}) => {
  if (!input.loan) return false;

  return (
    (input.loan.isStripeEnabled && input.stripePaymentMethodCount > 0) ||
    (!input.loan.isStripeEnabled && input.payixPaymentMethodCount > 0)
  );
};

export const getUseablePaymentMethodsByLoan = (input: {
  loan: Nullable<ILoan>;
  stripePaymentMethods: IPaymentMethod[];
  payixPaymentMethods: IPaymentMethod[];
}): IPaymentMethod[] => {
  if (!input.loan) return [];
  return input.loan.isStripeEnabled ? input.stripePaymentMethods : input.payixPaymentMethods;
};

export const getPaymentInputSessionKey = (loan: ILoan | null) => {
  const loanId = loan ? loan.id : '';
  return `${CLIENT_STORAGE_KEY.CUSTOM_PAYMENT_AMOUNT}:${loanId}`;
};

export const getOwedAmountInDollars = (paymentDue: IPaymentDues | null | undefined): number => {
  const owedAmount = paymentDue ? paymentDue.amount.value - paymentDue.paidAmount.value : 0;
  const unit = paymentDue ? paymentDue.amount.unit : DEFAULT_CURRENCY_UNIT;
  return getAmountInDollars(owedAmount, unit);
};
