import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import firebaseClient from 'utils/firebase';
import { getAuth } from 'firebase/auth';
import { routes } from 'utils/routeHelper';

const AuthRoute = (props: RouteProps) => {
  const { currentUser } = getAuth(firebaseClient);

  if (currentUser) {
    return <Redirect to={routes.HOME} />;
  }
  return <Route {...props} />;
};

export default AuthRoute;
