import { useEffect, useState } from 'react';

import firebaseClient from 'utils/firebase';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { configureScopeWithUserId } from 'utils/sentry';

const useAuthenticationSubscriber = () => {
  const authFirebase = getAuth(firebaseClient);
  const analytics = getAnalytics(firebaseClient);
  const [auth, setAuth] = useState({
    isChecked: false,
    scratchBorrowerId: '',
  });

  useEffect(() => {
    const unsubscriber = authFirebase.onAuthStateChanged((user) => {
      setAuth({
        isChecked: true,
        scratchBorrowerId: user ? user.uid : '',
      });
      if (user) {
        setUserId(analytics, user.uid);
        configureScopeWithUserId(user.uid);
        if (window.heap) {
          window.heap.identify(user.uid);
        }
      }
    });
    return unsubscriber;
  }, []);

  return auth;
};

export default useAuthenticationSubscriber;
