import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';

import { routes } from 'utils/routeHelper';

import { IconBack, IconClose } from 'assets/icons';
import { ElevationAppbar } from 'components/widgets';
import { IStyleOptions } from 'components/widgets/ElevationAppbar';

import './Header.scss';

const DEFAULT_LEVEL_BY_ROUTE = {
  [routes.ROOT]: 0,
  [routes.LOGIN]: 0,
  [routes.OTP_CONFIRMATION]: 1,
  [routes.HOME]: 0, // Reset level after being signed in
  [routes.USER_PROFILE]: 1,
  [routes.CONTACT]: 1,
  [routes.MY_LOANS]: 1,
  [routes.MY_PAYMENT_METHODS]: 1,
  [routes.MY_PAYMENTS]: 1,
  [routes.MY_SCHEDULED_PAYMENTS]: 1,
  [routes.LOAN_OVERVIEW]: 1,
  [routes.PAYMENT_DETAILS]: 1,
  [routes.PAYMENT_METHOD_DETAILS]: 1,
  [routes.PAYMENT_OVERVIEW]: 2,
  [routes.PAYMENT_METHOD_OVERVIEW]: 2,
  [routes.MY_DOCUMENTS]: 1,
  [routes.EFT_AGREEMENT]: 1,
  [routes.AUTOPAY_AUTH_TERMS]: 1,
  [routes.SELECT_PAYMENT_METHOD]: 1,
};

interface IProps {
  onBack?: () => void;
  styleOptions?: IStyleOptions;
}

function getRouteLevel(route: string) {
  let level = DEFAULT_LEVEL_BY_ROUTE[route];
  if (level === undefined) {
    level = route.split('/').length >= 3 ? 1 : 0;
  }
  return level;
}

const Header = (props: IProps) => {
  const { styleOptions } = props;
  const history = useHistory();
  const lastLocation = useLastLocation();

  const currentRoute = history.location.pathname;
  const level = getRouteLevel(currentRoute);
  const isBackVisible = level >= 1;
  const isCloseVisible = level === 2;

  const handleOnGoBack = () => {
    if (props.onBack) {
      // Used for Confirmation Code page
      props.onBack();
      return;
    }

    if (!lastLocation) {
      history.push(routes.HOME);
      return;
    }

    history.goBack();
  };

  const handleOnClose = () => {
    history.push(routes.HOME);
  };
  const colorScheme = {
    dark: styleOptions && styleOptions.isDarkBlue,
    light: styleOptions && styleOptions.isLight,
  };

  return (
    <ElevationAppbar styleOptions={styleOptions}>
      <Fragment>
        {isBackVisible && (
          <IconButton onClick={handleOnGoBack} styleName={classNames('btn icon-back', colorScheme)}>
            <IconBack />
            <span styleName="icon-text">Back</span>
          </IconButton>
        )}
        {isCloseVisible && (
          <IconButton onClick={handleOnClose} styleName={classNames('btn icon-close', colorScheme)}>
            <IconClose />
          </IconButton>
        )}
      </Fragment>
    </ElevationAppbar>
  );
};

export default Header;
