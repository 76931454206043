import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { getScheduledPaymentsSuccess } from 'actions/payment';
import { getCachingLoansRequest, getCachingLoansSuccess } from 'actions/loan';
import firebaseClient, { getOrderedLoansQuery } from 'utils/firebase';
import { getAuth } from 'firebase/auth';
import { onSnapshot } from 'firebase/firestore';
import { checkIsPaidScheduledPayment } from '../utils/payment';

import useLoanAndScheduledPaymentFetcher from './useLoanAndScheduledPaymentFetcher';

const useLoanAndScheduledPaymentSubscriber = () => {
  useLoanAndScheduledPaymentFetcher();
  const dispatch = useDispatch();
  const unsubscriberRef = useRef<Function>();
  const { currentUser } = getAuth(firebaseClient);
  const scratchBorrowerId = currentUser ? currentUser.uid : '';

  const unsubscribe = () => unsubscriberRef.current && unsubscriberRef.current();

  useEffect(() => {
    unsubscribe();
    if (scratchBorrowerId) {
      dispatch(getCachingLoansRequest());
      const loansQuery = getOrderedLoansQuery(scratchBorrowerId);
      if (loansQuery) {
        unsubscriberRef.current = onSnapshot(loansQuery, (querySnapshot) => {
          let loans: ILoan[] = [];
          let scheduledPayments: IScheduledPayment[] = [];
          querySnapshot.forEach((doc) => {
            const cachingLoan = doc.data();
            const { cannotFetchDetails, details, scheduledPayments: loanScheduledPayments, ...rest } = cachingLoan;
            const hasScheduledPayments = loanScheduledPayments.some(
              (scheduledPayment: IScheduledPayment) => !checkIsPaidScheduledPayment(scheduledPayment)
            );
            const loan = {
              ...rest,
              ...(details || { isPending: true }),
              hasScheduledPayments,
              cannotFetchDetails: details ? false : cannotFetchDetails,
            };
            loans.push(loan);
            scheduledPayments = scheduledPayments.concat(loanScheduledPayments || []);
          });
          dispatch(getScheduledPaymentsSuccess(scheduledPayments));
          dispatch(getCachingLoansSuccess(loans));
        });
      }
    }
    return unsubscribe;
  }, [scratchBorrowerId]);
};

export default useLoanAndScheduledPaymentSubscriber;
