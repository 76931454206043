import React from 'react';
import { Link } from 'react-router-dom';
import { SwipeableDrawer, List, ListItem } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';

import { INAPPBROWSER_SETTINGS, appVersion, isCordovaIOSApp } from 'utils/constants';
import { routes, externalLinks } from 'utils/routeHelper';

import { IconEmptyProfilePhoto, IconArrowRight } from 'assets/icons';

import styles from './NavigationDrawer.scss';
import { FindPlanButton } from 'components/widgets';
import * as loanSelectors from 'selectors/loan';
import { useSelector } from 'react-redux';

interface IListItemLink {
  text: string;
  path: string;
  isExternal?: boolean;
}

const MAIN_ITEMS: IListItemLink[] = [
  { text: 'My loans', path: routes.MY_LOANS },
  { text: 'My scheduled payments', path: routes.MY_SCHEDULED_PAYMENTS },
  { text: 'My payment methods', path: routes.MY_PAYMENT_METHODS },
  { text: 'My payments', path: routes.MY_PAYMENTS },
  { text: 'My documents', path: routes.MY_DOCUMENTS },
];

const SUB_ITEMS: IListItemLink[] = [
  { text: 'Find a practice', path: externalLinks.PRACTICES_SEARCH, isExternal: true },
  { text: 'Help center', path: externalLinks.HELP_CENTER, isExternal: true },
  { text: 'Legal', path: externalLinks.LEGAL, isExternal: true },
  { text: 'Contact', path: routes.CONTACT },
];

export const ListItemLink = ({ text, path, isExternal }: IListItemLink) => {
  if (isExternal && isCordovaIOSApp) {
    return (
      <div
        onClick={() =>
          window.cordova.InAppBrowser.open(
            path,
            INAPPBROWSER_SETTINGS.OPEN_IN_SYSTEM_BROWSER,
            INAPPBROWSER_SETTINGS.SHOW_LOCATION_BAR,
          )
        }
      >
        {text}
      </div>
    );
  } else if (isExternal) {
    return <a href={path}>{text}</a>;
  } else {
    return <Link to={path}>{text}</Link>;
  }
};

interface IProps {
  isOpenDrawer: boolean;
  fullName: string;
  onCloseDrawer: () => void;
  onOpenDrawer: () => void;
}

export const NavigationDrawer = (props: IProps & RouteComponentProps) => {
  const { isOpenDrawer, fullName, onCloseDrawer, onOpenDrawer } = props;
  const { hasDelinquentLoan } = useSelector((state: IRootState) => ({
    hasDelinquentLoan: loanSelectors.hasDelinquentLoan(state),
  }));

  return (
    <SwipeableDrawer
      open={isOpenDrawer}
      onClose={onCloseDrawer}
      onOpen={onOpenDrawer}
      PaperProps={{ className: styles['paper-wrapper'] }}
    >
      <div styleName="wrapper">
        <Link to={routes.USER_PROFILE} className="user-profile">
          <IconEmptyProfilePhoto styleName="icon-photo" />
          <div styleName="fullname">{fullName}</div>
          <IconArrowRight styleName="icon-arrow-right" />
        </Link>
        <div styleName="lists">
          <List styleName="list main">
            {MAIN_ITEMS.map((item: IListItemLink) => (
              <ListItem button key={item.text} styleName="list-item">
                <ListItemLink {...item} />
              </ListItem>
            ))}
          </List>
          {!hasDelinquentLoan && (
            <div styleName="button">
              <FindPlanButton />
            </div>
          )}
          <List styleName="list sub">
            {SUB_ITEMS.map((item: IListItemLink) => (
              <ListItem button key={item.text} styleName="list-item">
                <ListItemLink {...item} />
              </ListItem>
            ))}
          </List>
        </div>
        <div styleName="version">{`v.${appVersion}`}</div>
      </div>
    </SwipeableDrawer>
  );
};

export default withRouter(NavigationDrawer);
