import { useState, useEffect, ChangeEvent, KeyboardEvent, FocusEvent } from 'react';
import { isNil, isEmpty } from 'lodash';

import { REGEXES, isDecimalNumber } from 'utils/validate';
import {
  formatAmount,
  limitTwoDecimalPlaces,
  parseToNumber,
  removeLeadingZeros,
  toCurrencyFormat,
  undoFormatAmount,
} from 'utils/format';
import { TextFieldProps } from '@material-ui/core';

interface IHookArgs {
  value?: Nullable<string>;
  payoffBalance?: number;
  onChange?: (value: string) => void;
  onBlur?: TextFieldProps['onBlur'];
  onFocus?: TextFieldProps['onFocus'];
}

interface IState {
  internalValue: string;
  displayCurrencySymbol: boolean;
}

const MAX_ALLOWED_VALUE = 10000;
const initializeState = (value?: Nullable<string>): IState => {
  const internalValue = value || '';
  const displayCurrencySymbol = parseToNumber(internalValue) > 0;

  return {
    internalValue,
    displayCurrencySymbol,
  };
};

const usePaymentAmountInput = ({ value, payoffBalance = MAX_ALLOWED_VALUE, onChange, onBlur, onFocus }: IHookArgs) => {
  const [state, setState] = useState<IState>(initializeState(value));
  const { internalValue } = state;

  useEffect(() => {
    setState(initializeState(value));
  }, [value]);

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    const formattedValue = formatAmount(internalValue, { currencySymbol: '' });
    if (onChange) {
      onChange(formattedValue);
    }

    if (onBlur) onBlur(event);
  };

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (onFocus) onFocus(event);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxValue = payoffBalance;
    let { value: updatedValue } = event.target;
    updatedValue = undoFormatAmount(updatedValue);
    updatedValue = limitTwoDecimalPlaces(updatedValue);
    if (parseToNumber(updatedValue) > maxValue) updatedValue = String(maxValue);
    const isNotDecimaNumber = !isEmpty(updatedValue) && !isDecimalNumber(updatedValue);
    if (isNotDecimaNumber) return;
    updatedValue = toCurrencyFormat(updatedValue);
    updatedValue = removeLeadingZeros(updatedValue);

    setState((prevState) => ({
      ...prevState,
      internalValue: updatedValue,
    }));

    if (onChange) {
      const isInvalidValue = isNil(updatedValue) || isEmpty(updatedValue);
      const exportedValue = isInvalidValue ? '' : updatedValue;
      onChange(exportedValue);
    }
  };

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (event.key.toLowerCase() === 'backspace') return;
    const shouldIgnore = !REGEXES.NUMBER_ONLY.test(event.key);
    if (shouldIgnore) event.preventDefault();
  };

  return { state, handleOnBlur, handleOnFocus, handleOnChange, handleOnKeyDown };
};

export default usePaymentAmountInput;
