import { actionTypes } from 'actions/primayPaymentMethodInfo';

export const initialState: IPrimaryPaymentMethodInfoState = {
  isAddPrimaryPaymentMethod: false,
  loanId: null,
  tokenId: null,
};

export default function primaryPaymentMethodReducer(
  state: IPrimaryPaymentMethodInfoState = initialState,
  action: IAction<any>
): IPrimaryPaymentMethodInfoState {
  switch (action.type) {
    case actionTypes.SET_IS_ADD_PRIMARY_PAYMENT_METHOD: {
      const { payload: isAddPrimaryPaymentMethod } = action;
      return {
        ...state,
        isAddPrimaryPaymentMethod,
      };
    }
    case actionTypes.SET_PRIMARY_PAYMENT_METHOD_INFO: {
      const {
        payload: { loanId, tokenId },
      } = action;
      return {
        ...state,
        loanId,
        tokenId,
      };
    }
    case actionTypes.RESET_PRIMARY_PAYMENT_METHOD_INFO: {
      return initialState;
    }
    default:
      return state;
  }
}
