import React from 'react';
import Col from '../Col';
import './EFTAgreementBody.scss';
import { DATE_FORMATS, formatAmount, formatDate } from 'utils/format';

enum PaymentMethod {
  Card = 'card',
  Ach = 'ach',
}

export interface IProps {
  paymentMethodType: PaymentMethodType;
  borrowerName: string;
  paymentMethodLast4: string;
  paymentAmount: number;
  paymentDate: string;
}

const EFTAgreementBody = (props: IProps) => {
  const { paymentMethodType, borrowerName, paymentDate, paymentMethodLast4, paymentAmount } = props;

  const paymentInstrument = { [PaymentMethod.Ach]: 'bank account', [PaymentMethod.Card]: 'card information' }[
    paymentMethodType
  ];
  const paymentInstrumentCharge = { [PaymentMethod.Ach]: 'ACH Debit', [PaymentMethod.Card]: 'charge' }[
    paymentMethodType
  ];
  const paymentMethodText = { [PaymentMethod.Ach]: 'account', [PaymentMethod.Card]: 'card' }[paymentMethodType];
  const formatedDate = formatDate(paymentDate, { outputFormat: DATE_FORMATS.MM_DD_YY });
  const formatedAmount = formatAmount(paymentAmount);

  return (
    <div styleName="wrapper">
      <h1>Electronic Funds Transfer Agreement</h1>
      <h4>Details:</h4>
      <Col styleName="details">
        <p>
          {borrowerName}, by providing your {paymentInstrument}, and by clicking "Pay Now" today, {formatedDate}, you
          are authorizing Scratch Financial, Inc. to create a single {paymentInstrumentCharge} to your{' '}
          {paymentMethodText} ending in: {paymentMethodLast4} for the amount of {formatedAmount}
        </p>
        <p>
          This {paymentInstrumentCharge} will be applied to your {paymentMethodText} on or after {formatedDate}.
        </p>
      </Col>
    </div>
  );
};

export default EFTAgreementBody;
