import { actionTypes } from 'actions/internalServerErrorDialog';

export const initialState: IApiErrorDialogState = {
  isVisible: false,
};

export default function internalServerErrorDialogReducer(
  state: IApiErrorDialogState = initialState,
  action: IAction<any>
): IApiErrorDialogState {
  switch (action.type) {
    case actionTypes.SET_INTERNAL_SERVER_ERROR_DIALOG_VISIBLE: {
      return {
        ...state,
        isVisible: action.payload,
      };
    }
    default:
      return state;
  }
}
