import React from 'react';
import { History } from 'history';
import { Link, Redirect, useParams } from 'react-router-dom';

import { routes, externalLinks } from 'utils/routeHelper';

import { Footer } from 'components/layouts';
import { Col } from 'components/widgets';

import './MyLoanPaidOff.scss';
import { IconScratchpayHeart, IconPaidoffCalendar, IconPaidoffChat } from '../../assets/icons';
import { PaidoffPic } from '../../assets/images';
import { Box } from '@material-ui/core';

interface IProps {
  history: History;
}

export const MyLoanPaidOff = (props: IProps) => {
  const { uniqueId } = useParams();
  const selectedLoanId = uniqueId || localStorage.getItem('selectedLoanId');
  if (!selectedLoanId) return <Redirect to={routes.HOME} />;

  const handleFindPracticeClick = () => window.open(externalLinks.PRACTICES_SEARCH);
  const handleWriteReviewClick = () => window.open(externalLinks.WRITE_REVIEW);

  return (
    <>
      <div styleName="wrapper">
        <div styleName="wrapper-top">
          <Col xs={12} sm={6} md={4} styleName="col">
            <IconScratchpayHeart styleName="icon heart" />
            <PaidoffPic />
            <br />
            <br />
            <div styleName="title-text">Congrats!</div>
            <br />
            <div styleName="generic-text">
              Take some time to celebrate! You have successfully <strong>paid off</strong> your Scratch Plan{' '}
              {selectedLoanId}
            </div>
            <Col styleName="generic-col">
              <Box styleName="iconBox">
                <div styleName="icon">
                  <IconPaidoffChat />
                </div>
                <div styleName="text"></div>
              </Box>

              <div styleName="title-text">Share your experience</div>
              <div styleName="generic-text">
                Tell others about your Scratch Plans experience letting them know how it went!
              </div>
              <div styleName="centered">
                <button styleName="action-button" onClick={handleWriteReviewClick}>
                  Write a Review
                </button>
              </div>
            </Col>

            <Col styleName="generic-col">
              <Box styleName="iconBox">
                <div styleName="icon">
                  <IconPaidoffCalendar />
                </div>
                <div styleName="text"></div>
              </Box>

              <div styleName="title-text">Your next visit</div>
              <div styleName="generic-text">
                Planning a medical visit? Search our directory for practices offering Scratch Plans in dental, vision,
                cosmetic surgery, chiropractic care and more!
              </div>
              <div styleName="centered">
                <button styleName="action-button" onClick={handleFindPracticeClick}>
                  Find a Practice
                </button>
              </div>
            </Col>
            <div styleName="close-link-wrapper">
              <Link to={routes.HOME}>Close</Link>
            </div>
          </Col>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MyLoanPaidOff;
