import React, { useCallback, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { History } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { setPrimaryPaymentMethod, toggleAutopay } from 'actions/loan';

import { routes } from 'utils/routeHelper';

import { Footer } from 'components/layouts';
import { Col, IconWrapper, AutopayActivationBody, GifAnimationWrapper } from 'components/widgets';

import './AutopayActivation.scss';
import { IconScratchpayHeart } from '../../assets/icons';

import { useSelectedLoan } from 'hooks';
import * as loanSelectors from 'selectors/loan';
import * as paymentSelectors from 'selectors/payment';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import { buildAutopayAuthValue, buildPaymentMethodValue, buildPaymentMethodView } from 'utils/autopayAuth';

interface IProps {
  history: History;
}

export const AutopayActivation = (props: IProps) => {
  const [isEnablingAutopay, setIsEnablingAutopay] = useState<boolean>(false);
  const dispatch = useDispatch();

  // get selected loan
  const loan = useSelectedLoan();

  // get borrower payment methods
  const { isFetching, paymentMethods, selectedMethod } = useSelector((state: IRootState) => {
    const isFetchingLoans = loanSelectors.isFetchingSelector(state);
    const isSyncronizingLoans = loanSelectors.isSynchronizingSelector(state);
    const isFetchingPayment = paymentSelectors.isFetchingPaymentsSelector(state);
    const isFetchingPaymentMethod = paymentMethodSelectors.isFetchingSelector(state);
    const selectedMethod = paymentMethodSelectors.selectedPaymentMethodSelector(state);
    return {
      isFetching: isFetchingLoans || isSyncronizingLoans || isFetchingPayment || isFetchingPaymentMethod,
      paymentMethods: paymentMethodSelectors.paymentMethodsSelector(state),
      selectedMethod,
    };
  });

  const loanView = useMemo(() => buildAutopayAuthValue(loan), [loan]);
  const paymentMethod = useMemo(() => buildPaymentMethodValue(selectedMethod, paymentMethods), [paymentMethods]);
  const paymentMethodView = useMemo(() => buildPaymentMethodView(paymentMethod), [paymentMethod]);
  const autopayAuthTerms = useMemo(() => {
    return {
      loanId: loanView.loanId,
      paymentStartDate: loanView.paymentStartDate,
      paymentAmount: loanView.paymentAmount,
      paymentMethodLast4: paymentMethodView,
      remainingInstallments: loanView.remainingInstallments,
      frequency: loanView.frequency,
    } as IAutopayAuth;
  }, [loanView, paymentMethodView]);

  const handleEnableAutopay = useCallback(() => {
    setIsEnablingAutopay(true);
    dispatch(
      setPrimaryPaymentMethod({
        data: {
          loanId: loanView.loanId,
          tokenId: paymentMethod.id,
        },
      })
    );
    dispatch(
      toggleAutopay({
        data: {
          loanId: loanView.loanId,
          tokenId: paymentMethod.id,
          isEnabled: true,
        },
        onSuccess: () => {
          setIsEnablingAutopay(false);
          props.history.push(routes.HOME);
        },
        onError: () => {
          setIsEnablingAutopay(false);
        },
      })
    );
  }, [loanView, paymentMethod, isEnablingAutopay]);

  if (!loan) {
    return <Redirect to={routes.HOME} />;
  }

  if (!paymentMethod || !paymentMethod.id) {
    return <Redirect to={routes.PAYMENT_METHOD_DETAILS} />;
  }

  return (
    <React.Fragment>
      <div styleName="wrapper">
        <Col xs={12} sm={6} md={4} styleName="col">
          <IconWrapper>
            <IconScratchpayHeart />
          </IconWrapper>
          <AutopayActivationBody
            autopayAuthTerms={autopayAuthTerms}
            handleEnableAutopay={handleEnableAutopay}
            amountDue={loanView.delinquentAmount || 0}
          />
        </Col>
        <Footer />
      </div>
      {(isFetching || isEnablingAutopay) && <GifAnimationWrapper />}
    </React.Fragment>
  );
};

export default AutopayActivation;
