import { actionTypes, TAppAction } from 'actions/app';
import { routes } from 'utils/routeHelper';

export const initialState: IAppState = {
  returnUrl: routes.HOME,
};

export default function appReducer(state: IAppState = initialState, action: TAppAction): IAppState {
  switch (action.type) {
    case actionTypes.SET_RETURN_URL: {
      return {
        ...state,
        returnUrl: action.payload,
      };
    }
    default:
      return state;
  }
}
