import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import firebaseClient from 'utils/firebase';
import { getDocuments } from 'actions/documents';
import { getAuth } from 'firebase/auth';

const useDocumentsFetcher = () => {
  const dispatch = useDispatch();
  const { currentUser } = getAuth(firebaseClient);

  const fetchDocuments = () => dispatch(getDocuments());

  useEffect(() => {
    if (currentUser) {
      fetchDocuments();
    }
  }, [currentUser]);

  return { fetchDocuments };
};

export default useDocumentsFetcher;
