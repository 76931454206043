import React from 'react';
import { omit } from 'lodash';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';

import { makeRawPhoneNumber } from 'utils/format';

interface IPipeConfig {
  rawValue: string;
  previousConformedValue: string;
}

const MASK_PHONE_NUMBER = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const PHONE_NUMBER_REGEXES: { [field: string]: RegExp } = {
  EXACT_EXPECTED: new RegExp(/^\(\d{3}\) \d{3}-\d{4,}$/),
  INCLUDE_EXPECTATION: new RegExp(/^.*\(\d{3}\) \d{3}-\d{4,}$/),
  INCLUDE_AREA_CODE: new RegExp(/^\+\d\d*$/),
  START_WITH_ZERO: new RegExp(/^0{1,}1\d*$/),
};

const UNEXPECTED_SPECIAL_REGEX = /[\u202C]/;

const TextMaskPhoneNumber = (props: Partial<InputBaseComponentProps>) => {
  const { inputRef, ...otherProps } = props;

  const getInputRef = (ref: Nullable<{ inputElement: any }>) => {
    inputRef(ref ? ref.inputElement : null);
  };

  const customPipe = (conformedValue: string, config: IPipeConfig) => {
    const { previousConformedValue, rawValue } = config;
    const rawValueWithoutSpecialChar = rawValue.replace(UNEXPECTED_SPECIAL_REGEX, '');

    if (
      previousConformedValue &&
      PHONE_NUMBER_REGEXES.EXACT_EXPECTED.test(previousConformedValue) &&
      PHONE_NUMBER_REGEXES.EXACT_EXPECTED.test(rawValueWithoutSpecialChar)
    ) {
      return rawValueWithoutSpecialChar;
    }

    if (PHONE_NUMBER_REGEXES.INCLUDE_EXPECTATION.test(rawValueWithoutSpecialChar)) {
      const replacedPhoneNumber = rawValueWithoutSpecialChar.replace(/^(.*)(\(\d{3}\) \d{3}-\d{4,})$/g, '$2');
      const value = conformToMask(replacedPhoneNumber, MASK_PHONE_NUMBER, {
        guide: false,
      });
      return value.conformedValue;
    }

    const valueWithoutSeparators = makeRawPhoneNumber(rawValueWithoutSpecialChar);
    if (PHONE_NUMBER_REGEXES.INCLUDE_AREA_CODE.test(valueWithoutSeparators)) {
      const replacedValue = valueWithoutSeparators.replace(/^(\+)(0*)(\d*)$/g, '$1$3');
      const phoneNumber = replacedValue.slice(2);
      const value = conformToMask(phoneNumber, MASK_PHONE_NUMBER, {
        guide: false,
      });
      return value.conformedValue;
    }

    if (PHONE_NUMBER_REGEXES.START_WITH_ZERO.test(rawValueWithoutSpecialChar)) {
      const replacedValue = rawValueWithoutSpecialChar.replace(/^(0*)(1)(\d*)$/g, '$3');
      const value = conformToMask(replacedValue, MASK_PHONE_NUMBER, {
        guide: false,
      });

      return value.conformedValue;
    }

    return conformedValue;
  };

  return (
    <MaskedInput
      {...omit(otherProps, ['areaCode'])}
      ref={getInputRef}
      showMask
      guide={false}
      mask={MASK_PHONE_NUMBER}
      placeholderChar={'\u2000'}
      pipe={customPipe}
    />
  );
};

export default TextMaskPhoneNumber;
