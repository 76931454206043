import React from 'react';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';
import { IconScratchpayHeart } from 'assets/icons';
import { Footer, Header } from 'components/layouts';
import { Col, AutopayAuthTermsBody, IconWrapper } from 'components/widgets';
import './AutopayAuthTerms.scss';

export interface AutopayAuthTermsState {
  autopayAuthTerms: IAutopayAuth;
}

const AutopayAuthTerms = () => {
  const { state } = useLocation() as Location<AutopayAuthTermsState>;
  const { autopayAuthTerms } = state;

  return (
    <div styleName="wrapper">
      <Header styleOptions={{ isSticky: false, isLight: true }} />
      <Col xs={12} sm={6} md={4} styleName="col">
        <IconWrapper>
          <IconScratchpayHeart />
        </IconWrapper>
        <AutopayAuthTermsBody {...autopayAuthTerms} />
      </Col>
      <Footer />
    </div>
  );
};

export default AutopayAuthTerms;
