import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { getCachedBorrowerRequest, getCachedBorrowerSuccess } from 'actions/user';
import firebaseClient, { getBorrowerCacheQuery } from 'utils/firebase';
import { getAuth } from 'firebase/auth';
import { onSnapshot } from 'firebase/firestore';

import useBorrowerFetcher from './useBorrowerFetcher';

const useBorrowerSubscriber = () => {
  useBorrowerFetcher();
  const dispatch = useDispatch();
  const unsubscriberRef = useRef<Function>();
  const { currentUser } = getAuth(firebaseClient);
  const scratchBorrowerId = currentUser ? currentUser.uid : '';

  const unsubscribe = () => unsubscriberRef.current && unsubscriberRef.current();

  useEffect(() => {
    unsubscribe();
    if (scratchBorrowerId) {
      dispatch(getCachedBorrowerRequest());
      const borrowerCacheQuery = getBorrowerCacheQuery(scratchBorrowerId);
      if (borrowerCacheQuery) {
        unsubscriberRef.current = onSnapshot(borrowerCacheQuery, (querySnapshot) => {
          if (querySnapshot.exists()) {
            const { borrower } = querySnapshot.data() as { borrower: IBorrower };
            dispatch(getCachedBorrowerSuccess(borrower));
          }
        });
      }
    }
    return unsubscribe;
  }, [scratchBorrowerId]);
};

export default useBorrowerSubscriber;
