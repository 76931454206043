import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { renewBorrower } from 'actions/user';
import firebaseClient from 'utils/firebase';
import { getAuth } from 'firebase/auth';

const useBorrowerFetcher = () => {
  const dispatch = useDispatch();
  const { currentUser } = getAuth(firebaseClient);

  const fetchBorrower = () => dispatch(renewBorrower());

  useEffect(() => {
    if (currentUser) {
      fetchBorrower();
    }
  }, [currentUser]);

  return { fetchBorrower };
};

export default useBorrowerFetcher;
