import React from 'react';
import { formatAmount } from 'utils/format';
import './AutopayActivationDelinquencyNotice.scss';

export interface IAutopayActivationDelinquencyNoticeProps {
  outstandingBalance: number;
}

export const AutopayActivationDelinquencyNotice = (props: IAutopayActivationDelinquencyNoticeProps) => {
  const formattedAmount = formatAmount(props.outstandingBalance);

  return (
    <div styleName="delinquency-notice">
      <h3>Your plan is past due.</h3>
      <p>
        To turn on autopay, please bring your account up to date. The outstanding balance is {formattedAmount}, and by
        proceeding, you agree to pay it today.
      </p>
    </div>
  );
};
