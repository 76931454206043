import React from 'react';

import { Dialog } from 'components/widgets';
import { Props as CustomizedDialogProps } from 'components/widgets/Dialog';

type Props = Pick<CustomizedDialogProps, 'isOpen' | 'onClose'> & {
  onDisableAutopay: () => void;
};

const DisableAutopayDialog = (props: Props) => (
  <Dialog
    isOpen={props.isOpen}
    onClose={props.onClose}
    title="Are you sure you want to disable Autopay for this plan?"
    actions={[
      {
        text: 'Cancel',
        type: 'negative',
        onClick: props.onClose,
      },
      {
        text: 'Disable',
        onClick: props.onDisableAutopay,
      },
    ]}
  >
    <div>If you disable Autopay, please ensure payments are made by the due date to avoid late fees.</div>
  </Dialog>
);

export default DisableAutopayDialog;
