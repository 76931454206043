import React from 'react';

import { PAYMENT_METHOD_TYPES } from 'utils/constants';

import { IconCard, IconBank } from 'assets/icons';

import './IconPaymentMethod.scss';

interface IProps {
  paymentType: string;
}

const IconPaymentMethod = ({ paymentType }: IProps) => {
  switch (paymentType) {
    case PAYMENT_METHOD_TYPES.CARD:
      return <IconCard styleName="icon card" />;
    case PAYMENT_METHOD_TYPES.ACH:
    case PAYMENT_METHOD_TYPES.PAD:
      return <IconBank styleName="icon bank" />;
    default:
      return null;
  }
};

export default IconPaymentMethod;
