import React from 'react';
import { useWipPromoEndDate } from 'hooks';

import './WipPromotionBody.scss';

const WipPromotionBody = () => {
  const date = useWipPromoEndDate();
  if (!date) return null;
  return (
    <div styleName="wrapper">
      Zero Interest if you pay off your plan by: <span styleName="wip-end-date">{date}</span>
    </div>
  );
};

export default WipPromotionBody;
