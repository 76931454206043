import React from 'react';
import ReactDOM from 'react-dom';

import { isCordovaApp, isCordovaIOSApp } from 'utils/constants';
import { intializeSentry } from 'utils/sentry';

import Root from './Root';
import * as serviceWorker from './serviceWorker';
import { initializeFirebase } from 'utils/firebase';

const startApp = () => {
  intializeSentry({ environment: process.env.DEPLOYMENT_ENV || '' });
  initializeFirebase();
  ReactDOM.render(<Root />, document.getElementById('root'));
};

if (isCordovaApp) {
  document.addEventListener('deviceready', startApp, false);
  if (isCordovaIOSApp) {
    const iosViewportMetaTag = 'user-scalable=no, initial-scale=1, width=device-width, viewport-fit=cover';
    const metaViewport = document.createElement('meta');
    metaViewport.httpEquiv = 'viewport';
    metaViewport.content = iosViewportMetaTag;
    document.head.appendChild(metaViewport);
  }
} else {
  startApp();
}

serviceWorker.unregister();
