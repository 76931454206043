import React from 'react';
import { useDispatch } from 'react-redux';

import { routes } from 'utils/routeHelper';

import { Col, NavigationLink, UserDecisionWrapper } from 'components/widgets';
import AutopayActivationLabel from './AutopayActivationLabel';

import './AutopayActivationBody.scss';
import { setReturnUrl } from '../../../actions/app';
import { AutopayActivationDelinquencyNotice } from './AutopayActivationDelinquencyNotice';

export interface IAutopayActivationBodyProps {
  autopayAuthTerms: IAutopayAuth;
  handleEnableAutopay: () => void;
  amountDue: number;
}

export const AutopayActivationBody = (props: IAutopayActivationBodyProps) => {
  const { autopayAuthTerms, handleEnableAutopay, amountDue } = props;
  const dispatch = useDispatch();
  const hasPastDue = amountDue > 0;

  const handleSetReturnUrl = () => dispatch(setReturnUrl(routes.AUTOPAY_ACTIVATION));
  const agreeButtonText = hasPastDue ? 'Agree and Pay' : 'I Agree';

  return (
    <div styleName="wrapper">
      <Col styleName="details">
        <h2>Autopay Authorization</h2>
        <br />
        <div styleName="generic-text">
          By using Autopay, you authorize Scratch Financial to electronically withdraw the payment amount at the
          designated frequency from the payment method below.
        </div>
        <br />
        <div styleName="terms-text">
          <NavigationLink
            text="Autopay Authorization Terms"
            to={{
              pathname: routes.AUTOPAY_AUTH_TERMS,
              state: {
                autopayAuthTerms,
              },
            }}
          />
        </div>
        <br />
        <div styleName="main-box">
          <AutopayActivationLabel label="Plan ID" value={autopayAuthTerms.loanId} />
          <div />
          <AutopayActivationLabel label="Start Date" value={autopayAuthTerms.paymentStartDate} />
          <div />
          <AutopayActivationLabel label="Payment Amount" value={autopayAuthTerms.paymentAmount} />
          <div />
          <AutopayActivationLabel label="Payment Method" value={autopayAuthTerms.paymentMethodLast4} />
          <NavigationLink text="Edit" to={routes.SELECT_PAYMENT_METHOD} onClick={handleSetReturnUrl} />
          <AutopayActivationLabel label="# of payments" value={autopayAuthTerms.remainingInstallments} />
          <div />
          <AutopayActivationLabel label="Frequency" value={autopayAuthTerms.frequency} />
          <div />
        </div>
        {hasPastDue && <AutopayActivationDelinquencyNotice outstandingBalance={amountDue} />}
      </Col>
      <UserDecisionWrapper buttonText={agreeButtonText} buttonProps={{ onClick: handleEnableAutopay }} />
    </div>
  );
};

export default AutopayActivationBody;
