import { createSelector } from 'reselect';

export const primaryPaymentMethodInfoSelector = (state: IRootState) => state.primaryPaymentMethodInfo;

export const isAddPrimaryPaymentMethodSelector = createSelector(
  primaryPaymentMethodInfoSelector,
  ({ isAddPrimaryPaymentMethod }) => isAddPrimaryPaymentMethod
);

export const primaryPaymentMethodInfoLoanIdSelector = createSelector(
  primaryPaymentMethodInfoSelector,
  ({ loanId }) => loanId
);

export const primaryPaymentMethodInfoTokenIdSelector = createSelector(
  primaryPaymentMethodInfoSelector,
  ({ tokenId }) => tokenId
);
