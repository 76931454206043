import React from 'react';
import { lowerCase } from 'lodash';

import { PAYMENT_METHOD_TYPES, DEFAULT_VALUE_MISSING_FIELDS } from 'utils/constants';
import {
  formatAmount,
  formatFeeAmount,
  formatDate,
  formatHiddenDigitsWithLast4,
  formatTotalAmount,
} from 'utils/format';

import { PaymentMethodLogo } from 'components/widgets';

import paymentOverviewBodyStyle from './PaymentOverviewBody.scss';

const PAYMENT_METHOD_TYPE_LABEL = {
  [PAYMENT_METHOD_TYPES.ACH]: 'ACH',
  [PAYMENT_METHOD_TYPES.CARD]: 'Card',
  [PAYMENT_METHOD_TYPES.PAD]: 'PAD',
};

export interface IPaymentOverviewCommonValue {
  paymentDate: string;
  holderName: string;
  last4: string;
  paymentMethodType: string;
  totalAmount: string;
  paymentAmount: string;
  paymentConfirmationNumber: string;
}

export const buildCommonValueForPaymentOverview = (payment: IPayment): IPaymentOverviewCommonValue => {
  const {
    confirmId,
    principalAndInterestAmount,
    transactionDate,
    totalAmount,
    paymentMethod: { type, holderName, last4 },
    isRefund,
  } = payment;
  let paymentMethodType = type ? PAYMENT_METHOD_TYPE_LABEL[type] : DEFAULT_VALUE_MISSING_FIELDS;
  if (!paymentMethodType) paymentMethodType = type;

  return {
    holderName: holderName || DEFAULT_VALUE_MISSING_FIELDS,
    last4: last4 ? formatHiddenDigitsWithLast4(type, last4) : DEFAULT_VALUE_MISSING_FIELDS,
    paymentDate: formatDate(transactionDate),
    paymentMethodType: paymentMethodType.toUpperCase(),
    totalAmount: formatAmount(totalAmount),
    paymentAmount: isRefund ? formatAmount(totalAmount) : formatAmount(principalAndInterestAmount),
    paymentConfirmationNumber: confirmId || DEFAULT_VALUE_MISSING_FIELDS,
  };
};

type TypeBaseStyle = Record<string, string>;

const DEFAULT_OVERVIEW_ITEM_OPTIONS: {
  cardBrand?: string;
  isACHOrPAD?: boolean;
} = {
  cardBrand: undefined,
  isACHOrPAD: false,
};

interface IProps {
  isScheduledPayment: boolean;
  payment: IPayment | IScheduledPayment;
  baseStyle?: TypeBaseStyle;
  isRenderedForReceiptTemplate?: boolean;
}

const PaymentOverviewBody = ({
  isScheduledPayment,
  payment,
  baseStyle = paymentOverviewBodyStyle,
  isRenderedForReceiptTemplate = false,
}: IProps) => {
  const renderTotalAmount = (totalAmountValue: string) => (
    <div className={baseStyle['total-payment-amount']}>
      <div className={baseStyle['total-payment-amount-label']}>Total amount</div>
      <div className={baseStyle['total-payment-amount-value']}>{totalAmountValue}</div>
    </div>
  );

  const renderOverviewItem = (label: string, value: string, options = DEFAULT_OVERVIEW_ITEM_OPTIONS) => {
    const { cardBrand, isACHOrPAD } = options;
    return (
      <div className={baseStyle.item}>
        <div className={baseStyle.label}>{label}</div>
        <div className={baseStyle.value}>
          {!!cardBrand && <PaymentMethodLogo isCard cardBrand={cardBrand} />}
          {!!isACHOrPAD && <PaymentMethodLogo isCard={false} />}
          {value}
        </div>
      </div>
    );
  };

  const renderRefund = (isRefund: Undefinable<boolean>) => (
    <div styleName="tag-refund-wrapper">{isRefund && <div styleName="tag-refund">Refund</div>}</div>
  );

  const renderScheduledPaymentOverviewBody = (scheduledPayment: IScheduledPayment) => {
    const { principalAmount, lateFeeAmount, convenienceFeeAmount, token, transaction, loanId } = scheduledPayment;

    const totalAmountValue = formatTotalAmount([principalAmount, lateFeeAmount, convenienceFeeAmount]);
    const convenienceFeeAmountValue = formatFeeAmount(convenienceFeeAmount);
    const lateFeeAmountValue = formatFeeAmount(lateFeeAmount);

    const paymentType = token ? token.paymentMethod : undefined;
    const isCard = !!token && token.paymentMethod === PAYMENT_METHOD_TYPES.CARD;
    const cardBrand = isCard ? token!.type : undefined;

    return (
      <>
        {renderTotalAmount(totalAmountValue)}
        <div styleName={`blocks ${paymentType ? lowerCase(PAYMENT_METHOD_TYPE_LABEL[paymentType]) : ''}`}>
          <div className={baseStyle.block}>
            {renderOverviewItem('Payment amount', formatAmount(principalAmount))}
            {convenienceFeeAmountValue && renderOverviewItem('Convenience fee', convenienceFeeAmountValue)}
            {lateFeeAmountValue && renderOverviewItem('Late fee', lateFeeAmountValue)}
          </div>
          <div className={baseStyle.block}>
            {token && transaction && (
              <>
                {!isRenderedForReceiptTemplate && renderOverviewItem('Payment date', formatDate(transaction.paidAt))}
                {renderOverviewItem('Payment confirmation number', transaction.orderId)}
                {renderOverviewItem(
                  'Payment method type',
                  isCard ? 'Card' : (token.paymentMethod || DEFAULT_VALUE_MISSING_FIELDS).toUpperCase()
                )}
                {renderOverviewItem('Card owner', token.name)}
                {renderOverviewItem('Card number', formatHiddenDigitsWithLast4(token.paymentMethod, token.last4), {
                  cardBrand,
                })}
              </>
            )}
            {renderOverviewItem('Loan ID', loanId)}
          </div>
        </div>
      </>
    );
  };

  const renderPaymentOverviewBodyWithCard = (input: {
    commonValue: IPaymentOverviewCommonValue;
    loanId: string;
    convenienceFeeAmount?: number;
    lateFeeAmount?: number;
    card?: Nullable<IPaymentMethodCard>;
    isRefund: Undefinable<boolean>;
  }) => {
    const { commonValue, loanId, convenienceFeeAmount, lateFeeAmount, card, isRefund } = input;
    const convenienceFeeAmountValue = formatFeeAmount(convenienceFeeAmount);
    const lateFeeAmountValue = formatFeeAmount(lateFeeAmount);
    const cardBrand = card ? card.cardBrand : DEFAULT_VALUE_MISSING_FIELDS;
    return (
      <>
        {renderRefund(isRefund)}
        {renderTotalAmount(commonValue.totalAmount)}
        <div styleName="blocks card">
          <div styleName="block">
            {renderOverviewItem('Payment amount', commonValue.paymentAmount)}
            {convenienceFeeAmountValue && renderOverviewItem('Convenience fee', convenienceFeeAmountValue)}
            {lateFeeAmountValue && renderOverviewItem('Late fee', lateFeeAmountValue)}
          </div>
          <div styleName="block">
            {!isRenderedForReceiptTemplate && renderOverviewItem('Payment date', commonValue.paymentDate)}
            {renderOverviewItem('Payment confirmation number', commonValue.paymentConfirmationNumber)}
            {renderOverviewItem('Payment method type', 'Card')}
            {renderOverviewItem('Card owner', commonValue.holderName)}
            {renderOverviewItem('Card number', commonValue.last4, { cardBrand })}
            {renderOverviewItem('Loan ID', loanId)}
          </div>
        </div>
      </>
    );
  };

  const renderPaymentOverviewBodyWithACH = (input: {
    commonValue: IPaymentOverviewCommonValue;
    loanId: string;
    lateFeeAmount?: number;
    ach?: Nullable<IPaymentMethodACH>;
    isRefund: Undefinable<boolean>;
  }) => {
    const { commonValue, loanId, lateFeeAmount, ach, isRefund } = input;
    const lateFeeAmountValue = formatFeeAmount(lateFeeAmount);
    const bankName = ach ? ach.bankName : DEFAULT_VALUE_MISSING_FIELDS;
    return (
      <>
        {renderRefund(isRefund)}
        {renderTotalAmount(commonValue.totalAmount)}
        <div styleName="blocks ach">
          <div styleName="block">
            {renderOverviewItem('Payment amount', commonValue.paymentAmount)}
            {lateFeeAmountValue && renderOverviewItem('Late fee', lateFeeAmountValue)}
          </div>
          <div styleName="block">
            {!isRenderedForReceiptTemplate && renderOverviewItem('Payment date', commonValue.paymentDate)}
            {renderOverviewItem('Payment confirmation number', commonValue.paymentConfirmationNumber)}
            {renderOverviewItem('Payment method type', commonValue.paymentMethodType)}
            {renderOverviewItem('Bank name', bankName)}
            {renderOverviewItem('Bank account owner', commonValue.holderName)}
            {renderOverviewItem('Account number', commonValue.last4, { isACHOrPAD: true })}
            {renderOverviewItem('Loan ID', loanId)}
          </div>
        </div>
      </>
    );
  };

  const renderPaymentOverviewBodyWithPAD = (input: {
    commonValue: IPaymentOverviewCommonValue;
    loanId: string;
    lateFeeAmount?: number;
    pad?: Nullable<IPaymentMethodPAD>;
    isRefund: Undefinable<boolean>;
  }) => {
    const { commonValue, loanId, lateFeeAmount, pad, isRefund } = input;
    const lateFeeAmountValue = formatFeeAmount(lateFeeAmount);
    const bankName = pad ? pad.bankName : DEFAULT_VALUE_MISSING_FIELDS;
    return (
      <>
        {renderRefund(isRefund)}
        {renderTotalAmount(commonValue.totalAmount)}
        <div styleName="blocks pad">
          <div styleName="block">
            {renderOverviewItem('Payment amount', commonValue.paymentAmount)}
            {lateFeeAmountValue && renderOverviewItem('Late fee', lateFeeAmountValue)}
          </div>
          <div styleName="block">
            {!isRenderedForReceiptTemplate && renderOverviewItem('Payment date', commonValue.paymentDate)}
            {renderOverviewItem('Payment confirmation number', commonValue.paymentConfirmationNumber)}
            {renderOverviewItem('Payment method type', commonValue.paymentMethodType)}
            {renderOverviewItem('Bank name', bankName)}
            {renderOverviewItem('Bank account owner', commonValue.holderName)}
            {renderOverviewItem('Account number', commonValue.last4, { isACHOrPAD: true })}
            {renderOverviewItem('Loan ID', loanId)}
          </div>
        </div>
      </>
    );
  };

  const renderPaymentOverviewBodyWithUnknownMethod = (input: {
    commonValue: IPaymentOverviewCommonValue;
    loanId: string;
    convenienceFeeAmount?: number;
    lateFeeAmount?: number;
    isRefund: Undefinable<boolean>;
  }) => {
    const { commonValue, loanId, convenienceFeeAmount, lateFeeAmount, isRefund } = input;
    const convenienceFeeAmountValue = formatFeeAmount(convenienceFeeAmount);
    const lateFeeAmountValue = formatFeeAmount(lateFeeAmount);
    return (
      <>
        {renderRefund(isRefund)}
        {renderTotalAmount(commonValue.totalAmount)}
        <div styleName="blocks">
          <div styleName="block">
            {renderOverviewItem('Payment amount', commonValue.paymentAmount)}
            {convenienceFeeAmountValue && renderOverviewItem('Convenience fee', convenienceFeeAmountValue)}
            {lateFeeAmountValue && renderOverviewItem('Late fee', lateFeeAmountValue)}
          </div>
          <div styleName="block">
            {!isRenderedForReceiptTemplate && renderOverviewItem('Payment date', commonValue.paymentDate)}
            {renderOverviewItem('Payment confirmation number', commonValue.paymentConfirmationNumber)}
            {renderOverviewItem('Payment method type', commonValue.paymentMethodType)}
            {renderOverviewItem('Owner', commonValue.holderName)}
            {renderOverviewItem('Loan ID', loanId)}
          </div>
        </div>
      </>
    );
  };

  if (isScheduledPayment) {
    return renderScheduledPaymentOverviewBody(payment as IScheduledPayment);
  }

  const {
    loanId,
    convenienceFeeAmount,
    lateFeeAmount,
    paymentMethod: { type, card, ach, pad },
    isRefund,
  } = payment as IPayment;
  const commonValue = buildCommonValueForPaymentOverview(payment as IPayment);
  switch (type) {
    case PAYMENT_METHOD_TYPES.CARD:
      return renderPaymentOverviewBodyWithCard({
        commonValue,
        loanId,
        convenienceFeeAmount,
        lateFeeAmount,
        card,
        isRefund,
      });
    case PAYMENT_METHOD_TYPES.ACH:
      return renderPaymentOverviewBodyWithACH({
        commonValue,
        loanId,
        lateFeeAmount,
        ach,
        isRefund,
      });
    case PAYMENT_METHOD_TYPES.PAD:
      return renderPaymentOverviewBodyWithPAD({
        commonValue,
        loanId,
        lateFeeAmount,
        pad,
        isRefund,
      });
    default:
      return renderPaymentOverviewBodyWithUnknownMethod({
        commonValue,
        loanId,
        convenienceFeeAmount,
        lateFeeAmount,
        isRefund,
      });
  }
};

export default PaymentOverviewBody;
