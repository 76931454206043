import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import firebaseClient from 'utils/firebase';
import { getAuth } from 'firebase/auth';
import { renewLoans } from 'actions/loan';

const useLoanAndScheduledPaymentFetcher = () => {
  const dispatch = useDispatch();
  const { currentUser } = getAuth(firebaseClient);

  const fetchLoansAndScheduledPayments = () => dispatch(renewLoans());

  useEffect(() => {
    if (currentUser) {
      fetchLoansAndScheduledPayments();
    }
  }, [currentUser]);

  return { fetchLoansAndScheduledPayments };
};

export default useLoanAndScheduledPaymentFetcher;
