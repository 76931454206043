import React from 'react';
import './IconWrapper.scss';

interface IProps {
  children: JSX.Element;
}

const IconWrapper = (props: IProps) => {
  const { children } = props;

  return (
    <div styleName="wrapper">
      <div styleName="icon">{children}</div>
    </div>
  );
};

export default IconWrapper;
