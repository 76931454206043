import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { routes } from 'utils/routeHelper';
import { setInternalServerErrorDialogVisible } from 'actions/internalServerErrorDialog';
import Dialog from './Dialog';

const InternalServerErrorDialog = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: IRootState) => state.internalServerErrorDialog.isVisible);

  const closeDialog = () => {
    dispatch(setInternalServerErrorDialogVisible(false));
  };

  const onClose = async () => {
    closeDialog();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Server issue"
      actions={[
        {
          text: 'OK',
          onClick: onClose,
        },
      ]}
    >
      <span>
        {"We're having troubles with our servers at the moment. Please try again later or"}
        &nbsp;
      </span>
      <Link to={routes.CONTACT} onClick={closeDialog}>
        Contact us
      </Link>
      <span>&nbsp;if the problem persists.</span>
    </Dialog>
  );
};

export default InternalServerErrorDialog;
