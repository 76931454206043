import { Modal } from '@material-ui/core';
import React from 'react';
import './ConvenienceFeeModal.scss';
import { formatAmount } from 'utils/format';
import { Link } from 'react-router-dom';

export interface IProps {
  show: boolean;
  setShow: (value: React.SetStateAction<boolean>) => void;
  convenienceFeeAmount: number;
  isAutopayEnabled: boolean;
}

const ConvenienceFeeModal = ({ show, setShow, convenienceFeeAmount, isAutopayEnabled }: IProps) => (
  <Modal open={show} onClose={() => setShow(false)}>
    <div styleName="modal-wrapper">
      <h1>Convenience Fees</h1>
      <p>
        When you pay with a card, a {formatAmount(convenienceFeeAmount)} Convenience Fee will be added. You can avoid
        this fee by paying with your bank account (ACH) or setting up Autopay.
      </p>
      <Link styleName="action" to="/my-payment-methods">
        Add Bank Account (ACH)
      </Link>
      {!isAutopayEnabled && (
        <Link styleName="action" to="/autopay-activation">
          Set Up Autopay
        </Link>
      )}
    </div>
  </Modal>
);

export default ConvenienceFeeModal;
