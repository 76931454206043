import React from 'react';
import { useAutopayToggle } from 'hooks';
import AutopayToggle from './AutopayToggle';
import DisableAutopayDialog from './DisableAutopayDialog';
import EnableAutopayDialog from './EnableAutopayDialog';
import GifAnimationWrapper from '../GifAnimationWrapper';

type TProps = Partial<React.ComponentProps<typeof AutopayToggle>> & { loan: ILoan };

const AutopayToggleWithDialog = (props: TProps) => {
  const { loan, ...rest } = props;

  const {
    isAutopayEnabled,
    isProcessingWithAutopay,
    isFetchingPaymentMethods,
    useablePaymentMethodsByLoan,
    isOpenDisableAutopayDialog,
    isOpenEnableAutopayDialog,
    handleOnCloseDisableAutopayDialog,
    handleOnCloseEnableAutopayDialog,
    handleOnDisableAutopay,
    handleOnToggleAutopay,
  } = useAutopayToggle(loan);

  return (
    <>
      <AutopayToggle
        disabled={isProcessingWithAutopay}
        onChange={handleOnToggleAutopay}
        {...rest}
        isEnabled={isAutopayEnabled}
      />
      <DisableAutopayDialog
        isOpen={isOpenDisableAutopayDialog}
        onClose={handleOnCloseDisableAutopayDialog}
        onDisableAutopay={handleOnDisableAutopay}
      />
      <EnableAutopayDialog
        isOpen={isOpenEnableAutopayDialog}
        onClose={handleOnCloseEnableAutopayDialog}
        loanId={loan.id}
        isFetchingPaymentMethods={isFetchingPaymentMethods}
        useablePaymentMethods={useablePaymentMethodsByLoan}
      />
      {isProcessingWithAutopay && <GifAnimationWrapper />}
    </>
  );
};

export default AutopayToggleWithDialog;
