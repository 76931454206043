import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import './NavigationLink.scss';

interface IProps {
  text: string;
  to: LinkProps['to'];
  onClick?: LinkProps['onClick'];
}

const NavigationLink = (props: IProps) => {
  return (
    <div styleName="navigation-link">
      <Link to={props.to} onClick={props.onClick}>
        {props.text}
      </Link>
    </div>
  );
};

export default NavigationLink;
