import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import firebaseClient from 'utils/firebase';
import { routes } from 'utils/routeHelper';
import { setSignInSuccessUrl } from 'actions/user';
import { getAuth } from 'firebase/auth';

const PrivateRoute = (props: RouteProps) => {
  const { currentUser } = getAuth(firebaseClient);
  const dispatch = useDispatch();
  const currentUserRef = useRef(currentUser);

  const saveSignInSuccessUrlIfNeeded = () => {
    if (!currentUserRef.current && props.location) {
      dispatch(setSignInSuccessUrl(props.location.pathname));
    }
  };

  if (!currentUser) {
    saveSignInSuccessUrlIfNeeded();
    return <Redirect to={routes.LOGIN} />;
  }

  return <Route {...props} />;
};

export default PrivateRoute;
